import React, { Component } from "react";

import $ from "jquery";
import queryString from "query-string";

//import { LoginWrapper, LoginBox, Input, Button, PostResult } from "./style";

import { getDateFromPeriodno } from "@/jscript/common.js";
import { AJAROOT } from "@/common/gdata/global.js";

class Confirmemail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Cancel sms ad",
      servicetitle: "",
      periodno: "",
      fromtime: "",
      employee: "",
      minutes: 0,
      name: "",
      telephone: "",
      token: "",
      postresult: "",
      posterror: "",
      exist: false,
      submited:false
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <div className="container wrap">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <span style={{ fontSize: "25px", fontWeight: "bold" }}>
              {this.state.title}
            </span>
          </div>
        </div>
       

      

        
        {!this.state.submited && (
          <div className="row" style={{ marginTop: "15px", padding: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
              <input
               style={{ width: "80%" }}
                type="button"
                name="schbut"
                value=" I don't mind it!"
                className="add"
                onClick={() => this.Confirm(1)}
              ></input>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-126 col-lg-12 textcenter" style={{ marginTop: "25px", padding: "10px" }}>
              {" "}
              <input
                  style={{ width: "80%" }}
                type="button"
                name="schbut2"
                value=" Dont't send me again!"
                className="add"
                onClick={() => this.Confirm(0)}
              ></input>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <span
              className={this.state.posterror === "" ? "textcenter" : "nodis"}
              style={{ fontSize: "25px" }}
            >
              {this.state.postresult}
            </span>
            <span
              className="loginerror textcenter"
              style={{ fontSize: "25px" }}
            >
              {this.state.posterror}
            </span>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    var { token } = queryString.parse(this.props.location.search);

    if (token == null) token = "";

    this.setState({
      token: token,
    });
  
    // this.login2(token);
  }

  Confirm(confirm) {
    // document.getElementById("result2").style.color = "#000000";
    if (confirm == 0) {
      let alterstr = "Are you sure  to cancel it";
      if (!window.confirm(alterstr)) return;
    }
    else 
    {
      this.setState({
        postresult: "Thanks for your trust.",
        submited:true
      });

      return;
    }

    this.setState({

      submited:true
    });

    this.setState({
      postresult: "Waiting...",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";

    const this2 = this;
    var error = "";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        posterror: error,
      });
    } else {
      let url =
        AJAROOT +
        "/getaja/xx.aspx?action=cancelsms&val=" +
        confirm +
        "&token=" +
        encodeURIComponent(this.state.token)+
        "";

      $.ajax({
        type: "GET",
        dataType: "json",
        url: url,
      })
        .done(function (data) {
  
          var r = data.r;
          var msg = data.s;

          let result = "";
          if (r === 1) {         
         
              result =
                "You have canceled our message ad. We will not send message to you.";        
            //  this.postresult = "登陆成功！";
            // this2.props.history.push("/");
            this2.setState({
              posterror: "",
              postresult: result,
              submited:true
            });
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              posterror: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
}

export default Confirmemail;
