import React, { Component } from "react";

import $ from "jquery";
import queryString from "query-string";

//import { LoginWrapper, LoginBox, Input, Button, PostResult } from "./style";

import { getDateFromPeriodno } from "@/jscript/common.js";
import { AJAROOT } from "@/common/gdata/global.js";

class Confirmemail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Confirm your book",
      servicetitle: "",
      periodno: "",
      fromtime: "",
      employee: "",
      minutes: 0,
      name: "",
      telephone: "",
      token: "",
      postresult: "",
      posterror: "",
      exist: false,
      submited:false
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <div className="container wrap">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <span style={{ fontSize: "25px", fontWeight: "bold" }}>
              {this.state.title}
            </span>
          </div>
        </div>
        {this.state.exist && (
          <div className="row" style={{ marginTop: "15px" }}>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bold">
                  Your Book Information
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                  Date && Time:
                </div>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  {getDateFromPeriodno(this.state.periodno)}{" "}
                  {" " + this.state.fromtime}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                  Service:
                </div>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  {this.state.servicetitle},{" " + this.state.minutes} Minutes
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                  Employee:
                </div>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  {this.state.employee}
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.exist && (
          <div className="row" style={{ marginTop: "15px" }}>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bold">
                  Your Information
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                  Name:
                </div>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  {this.state.name}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                  Telephone:
                </div>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  {this.state.telephone}
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.exist && (
          <div className="row" style={{ marginTop: "15px" }}>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bold">
                  The book doesn't exist or canceled.
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.exist&&!this.state.submited && (
          <div className="row" style={{ marginTop: "15px", padding: "10px" }}>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textcenter">
              <input
                type="button"
                name="schbut"
                value=" I Confirm It "
                className="add"
                onClick={() => this.Confirm(1)}
              ></input>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textcenter">
              {" "}
              <input
                type="button"
                name="schbut2"
                value=" I Cancel It "
                className="add"
                onClick={() => this.Confirm(0)}
              ></input>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <span
              className={this.state.posterror === "" ? "textcenter" : "nodis"}
              style={{ fontSize: "25px" }}
            >
              {this.state.postresult}
            </span>
            <span
              className="loginerror textcenter"
              style={{ fontSize: "25px" }}
            >
              {this.state.posterror}
            </span>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    var { token } = queryString.parse(this.props.location.search);

    if (token == null) token = "";

    this.setState({
      token: token,
    });
    this.getData(token);
    // this.login2(token);
  }

  Confirm(confirm) {
    // document.getElementById("result2").style.color = "#000000";
    if (confirm == 0) {
      let alterstr = "Are you sure to cancel it";
      if (!window.confirm(alterstr)) return;
    }
    this.setState({
      postresult: "Checking...",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";

    const this2 = this;
    var error = "";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        posterror: error,
      });
    } else {
      let url =
        AJAROOT +
        "/getaja/xx.aspx?action=confirmbook&val=" +
        confirm +
        "&token=" +
        encodeURIComponent(this.state.token)+
        "";

      $.ajax({
        type: "GET",
        dataType: "json",
        url: url,
      })
        .done(function (data) {
          // console.log(data);
          var r = data.r;
          var msg = data.s;

          let result = "";
          if (r === 1) {
          
            if (confirm == 0) {
              result =
                "You have canceled your reservation. We welcome you to book again in the future.";
            } else
              result =
                "We have confirmed your arrival and are looking forward to welcoming you.";
            //  this.postresult = "登陆成功！";
            // this2.props.history.push("/");
            this2.setState({
              posterror: "",
              postresult: result,
              submited:true
            });
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              posterror: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
  getData(token) {
    // document.getElementById("result2").style.color = "#000000";

    //document.getElementById("result2").style.color = "red";
    let that = this;
    let url =
      AJAROOT + "/getaja/xx.aspx?action=bookinfo_confirm&token=" + encodeURIComponent(token) + "";
     console.log(url);

    $.ajax({
      type: "GET",
      dataType: "json",
      url: url,
    })
      .done(function (data) {
        var r = data.r;

          console.log(data);

        if (r === 1) {
          that.setState({
            servicetitle: data.servicetitle,
            periodno: data.periodno,
            fromtime: data.fromtime,
            employee: data.employee,
            servicetitle: data.servicetitle,
            name: data.name,
            telephone: data.telephone,
            minutes: data.minutes,
            exist: true,
          });
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
}

export default Confirmemail;
