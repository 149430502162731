import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import queryString from "query-string";
import {
  checkUserLogin,
  getUserIndexData,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";

import "@/css/user.css";
import rjt from "../../../statics/rjt.png";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      editid: "",

      telephones: "",
      gifttype: "2",
      price: "",
      services: [],
      used: 0,

      postresult: "",
      servicelist: [],
      selectedlist: [],
      mytoast: {
        show: false,
      },
      num:500,
      content:"",
      addhz:"Y"
    };
  }
  render() {
    const { myusername } = this.props;
    //  let menujson = Menu();
    // console.log(menujson);

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">
          <div style={{ float: "left" }}>
            <Link to={"/user/sms/"}> Sms send</Link>{" "}

          
          </div>
          <div
            style={{
              float: "right",
            }}
          >
            <div
              style={{}}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              <span className="iconfont fanhui">&#xe625;</span>
            </div>
          </div>
        </div>
        {/* <div className="pagetitle">
          <Link to={"/user/branch/list"}>Branch List</Link>{" "}
          <img src={rjt} style={{ width: "18px", height: "18px" }} />{" "}
          {this.state.title}
        </div> */}
     {/*    <div className="row textright">
          <div>
            <Link to="/user/gift/List">
              <input
                type="button"
                name="button1"
                value="Gift List"
                className="add"
              ></input>
            </Link>
          </div>
        </div> */}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            {/* <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 ">
                姓名:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
                <input
                  value={this.state.upgzhnickname}
                  onChange={this.changeFirstName.bind(this)}
                />
              </div>
            </div> */}


            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * 范围:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  type="radio"
                  name="gifttype"
                  value="1"
                  onChange={(ref) => this.setRadio(ref, "gifttype")}
                  checked={this.state.gifttype == "1" ? true : false}
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                ></input>
                {" 按号码  "}
                <input
                  type="radio"
                  name="gifttype"
                  value="2"
                  onChange={(ref) => this.setRadio(ref, "gifttype")}
                  checked={this.state.gifttype == "2" ? true : false}
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                ></input>
                {" 按数量 "}
              </div>
            </div>
            
            {this.state.gifttype=='2'&&<div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * 填写每次发送数量:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.num}
                  
                  onChange={(ref) => this.changeField(ref, "num")}
                  
                />
               
              </div>
            </div>}
            
            {this.state.gifttype=='1'&&<div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * phone number(多个号码之间用;分开):
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <textarea
                  value={this.state.telephones}
                  readOnly={this.state.editid == "" ? false : true}
                  onChange={(ref) => this.changeField(ref, "telephones")}
                  style={{height:'150px',width:'500px'}}
                />
               
              </div>
            </div>}
            

           <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * 发送内容:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <textarea
                  value={this.state.content}
                
                  onChange={(ref) => this.changeField(ref, "content")}
                  style={{height:'100px',width:'500px'}}
                />
               
              </div>
            </div>

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                后缀:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
               <div >
               <input
                  type="radio"
                  name="addhz"
                  value="Y"
                  onChange={(ref) => this.setHz(ref, "addhz")}
                  checked={this.state.addhz == "Y" ? true : false}
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                ></input>
              添加取消后缀
                <input
                  type="radio"
                  name="addhz"
                  value="N"
                  onChange={(ref) => this.setHz(ref, "addhz")}
                  checked={this.state.addhz == "N" ? true : false}
                  style={{
                    width: "18px",
                    height: "18px",
                    marginLeft:"10px"
                  }}
                ></input>
              不加后缀
                     
                      </div>
              </div>
            </div>

           
            

            <div className="row">
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button className="submit" onClick={() => this.submit()}>
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                style={{ width: "70%", marginTop: "10px" }}
              >
                {this.state.postresult}
              </div>
            </div>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  setRadio(ref, field) {
    let val = ref.target.value;
    if (field == "gifttype")
      this.setState({
        gifttype: val,
      });
    else if (field == "received") {
      this.setState({
        received: val,
      });
    }
  }
  setHz(ref, field) {
    let val = ref.target.value;

      this.setState({
        addhz: val,
      });
 
 
  }
  setCheck(ref, index) {
    let selectedlist = this.state.selectedlist;
    if (ref.target.checked) {
      let item = {
        id: this.state.servicelist[index].id,
        title: this.state.servicelist[index].title,
      };
      let selected = this.state.selectedlist;
      selectedlist.push(item);
    } else {
      let _item = this.state.selectedlist.find(
        (tmpitem) => tmpitem.id === this.state.servicelist[index].id
      );
      if (_item != null) {
        let index = this.state.selectedlist.indexOf(_item);
        selectedlist.splice(index, 1);
      }
    }

    this.setState({
      selectedlist: selectedlist,
    });
  }
  changeField(e, field) {
    let val = e.target.value;
    if (field == "telephones")
      this.setState({
        telephones: val,
      });
    else if (field == "num") {
      if (!isNaN(val)) {
        this.setState({
            num: val,
        });
      }
      
    }
    else if (field == "content") {
     
        this.setState({
          content: val,
        });
      
      
    }
  }
  submit() {
    const { myusername, ukey } = this.props;

    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    let service = this.state.selectedlist;
    if (this.state.gifttype == "price") {
      service = [];
      this.setState({
        selectedlist: service,
      });
    }
    const postdata = {
      uid: myusername,
      content: this.state.content,
      gifttype: this.state.gifttype*1,
      telephones: this.state.telephones,
      num: this.state.num*1,
      addhz:this.state.addhz
    };
    const this2 = this;
    var error = "";

    if (this.state.content === "") error = "Please fill in content";
   

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=smssend&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          var r = data.r;

          if (r === 1) {
            //  this.postresult = "登陆成功！";
            MyToastShow(
              this2,
              2,
              "Successfully! ",
              150,
              80,
              "success",
              "addbranch"
            );
            this2.setState({
              postresult: "Successfully! ",
            });

          
          } else {
            MyToastShow(this2, 2, data.s, 200, 120, "fail", "addbranch");
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: data.s,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { id } = queryString.parse(this.props.location.search);
    if (id == undefined) id = "";

    var title = "Message send";

    if (id != "") {
      title = "Edit Gift";
    }
    document.title = title;
    this.setState({
      title: title,
      editid: id,
    });

    getUserIndexData(this, myusername, ukey);
    



    //  MyToastShow(this, 200, "Successfully! ", 150, 80, "success", "addgroup");
  }

 
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(Index);
