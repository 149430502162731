import { AJAROOT, bookButtonColor } from "@/common/gdata/global.js";
import React from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
//import errorPic from "../statics/error.png";
//import gouPic from "../statics/gou.png";
import { Fragment } from "react";
import {  MyToastShow,getPeriodnoFromDate_YYYYMMDD} from "@/jscript/common.js";
import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import "@/css/react-datepicker.css";
import "@/css/user.css";
import DatePicker from "react-datepicker";

const LCss = {
  agree: {
    padding: "15px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
  checkout: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },
  radio: {
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    marginRight: "3px",
  },
};

export function getHealthData(ele, username) {
  const { myusername, ukey } = ele.props;
  if (username == "") username = myusername;
  var geturl =
    AJAROOT +
    "/getaja/health.aspx?u=" +
    myusername +
    "&username=" +
    username +
    "&ukey=" +
    encodeURIComponent(ukey) +
    "";

  axios.get(geturl).then((res) => {
    const result = res.data;
  
    // this.setState({}); /**/
    let haveimg1 = 0;

    var firstname = result.firstname;
    var lastname = result.lastname;
    let birthday = result.birthday;
    if (birthday == null) birthday = "";
    if (birthday != "") {
      birthday = getPeriodnoFromDate_YYYYMMDD(new Date(birthday));
    //  birthday = new Date(birthday);
    }

    

    let initialdate = result.initialdate;
    if (initialdate == null) initialdate = "";
    if (initialdate != "") {
      initialdate = new Date(initialdate);
    }

    let clientsigndate = result.clientsigndate;
    if (clientsigndate == null) clientsigndate = "";
    if (clientsigndate != "") {
      clientsigndate = new Date(clientsigndate);
    }

    let clientsign = result.clientsign;
    if (clientsign == null) clientsign = "";
    if (clientsign != "") {
      haveimg1 = 1;
    }

    //this.canvasRef1.current.fromDataURL(patientsign);
    // this.canvasRef2.current.fromDataURL(guardiansign);
    // this.canvasRef3.current.fromDataURL(practitionersign);
    // if (zsxm === "") zsxm = username;
    ele.setState({
      firstname: firstname,
      lastname: lastname,
      telephone: result.telephone,
      address: result.address,

      birthday: birthday,
      initialdate: initialdate,
      occupation: result.occupation,
      hasmassagebefore: result.hasmassagebefore,
      haspractitionerrefer: result.haspractitionerrefer,
      refaddress: result.refaddress,

      conditiondata: result.conditiondata,

      selfoverall: result.selfoverall,
      physicianname: result.physicianname,
      physicianaddress: result.physicianaddress,

      currentmedication: result.currentmedication,
      currenttreat: result.currenttreat,
      havetreatment: result.havetreatment,
      whattreatment: result.whattreatment,
      surgerydate: result.surgerydate,
      surgerynature: result.surgerynature,
      injurydate: result.injurydate,
      injurynature: result.injurynature,
      haveothercondition: result.haveothercondition,
      whatcondition: result.whatcondition,
      haveequipment: result.haveequipment,
      whatequipment: result.whatequipment,
      whereequipment: result.whereequipment,
      massagereason: result.massagereason,

      date1: result.date1,
      date2: result.date2,
      date3: result.date3,
      date3: result.date3,
      date4: result.date4,
      clientname: result.clientname,

      clientsign: clientsign,
      clientsigndate: clientsigndate,

      haveimg1: haveimg1,
    });
  });
}
export function HealthForm(ele) {
  if (ele.state.conditiondata == null) return <></>;
  return (
    <>
      <div className="PageNext" id="content">
        {ele.state.isprint && (
          <div className="printtitle">{ele.state.title}</div>
        )}
        <div
          className="row"
          style={{ border: "1px solid #ddd", paddingTop: "10px" }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Name:
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textleft">
                {ele.state.firstname + ele.state.lastname}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
                Phone#:
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textleft">
                {ele.state.telephone}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Address:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                {ele.state.address}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Occupation:
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textleft">
                <input
                  className="printField"
                  value={ele.state.occupation}
                  onChange={(ref) => changeField(ele, ref, "occupation")}
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
                Date of Birth:
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textleft">
              <input
                  className="printField"
                   placeholder="YYYY/MM/DD"

                  value={ele.state.birthday}
                  onChange={(ref) => changeField(ele, ref, "birthday")}
                />
                {/* <DatePicker
                  className="printField"
                  selected={ele.state.birthday}
                  onChange={(ref) => setBirthday(ele, ref, "birthday")}
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Input birthday"
                /> */}
              </div>
            </div>

            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
                style={{
                  display: "flex",

                  alignContent: "center",
                }}
              >
                Have you received massage therapy before?
                <input
                  type="radio"
                  name="hasmassagebefore"
                  value="Y"
                  onChange={(ref) => setRadio(ele, ref, "hasmassagebefore")}
                  checked={ele.state.hasmassagebefore == "Y" ? true : false}
                  style={LCss.radio}
                ></input>
                {" Yes"}
                <input
                  type="radio"
                  name="hasmassagebefore"
                  value="N"
                  onChange={(ref) => setRadio(ele, ref, "hasmassagebefore")}
                  checked={ele.state.hasmassagebefore == "N" ? true : false}
                  style={LCss.radio}
                ></input>
                {" No"}
              </div>
            </div>

            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
                style={{
                  display: "flex",

                  alignContent: "center",
                }}
              >
                Did a health care practitioner refer you for massage therapy?
                <input
                  type="radio"
                  name="haspractitionerrefer"
                  value="Y"
                  onChange={(ref) => setRadio(ele, ref, "haspractitionerrefer")}
                  checked={ele.state.haspractitionerrefer == "Y" ? true : false}
                  style={LCss.radio}
                ></input>
                {" Yes"}
                <input
                  type="radio"
                  name="haspractitionerrefer"
                  value="N"
                  onChange={(ref) => setRadio(ele, ref, "haspractitionerrefer")}
                  checked={ele.state.haspractitionerrefer == "N" ? true : false}
                  style={LCss.radio}
                ></input>
                {" No"}
              </div>
            </div>

            <div className="row ">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
                style={{
                  display: "flex",

                  alignContent: "center",
                }}
              >
                If yes, please provide their name and address.
                <input
                  className="printField"
                  value={ele.state.refaddress}
                  onChange={(ref) => changeField(ele, ref, "refaddress")}
                  style={{
                    marginLeft: "10px",
                    width: "500px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
            style={{ fontSize: "16px", marginTop: "10px" }}
          >
            Please indicate condition you are experiencing or have experienced
          </div>
        </div>
        <div
          className="row"
          style={{ border: "1px solid #ddd", paddingTop: "10px" }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            <div className="row ">
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textleft">
                {ele.state.conditiondata.slice(0, 2).map((item, index) => {
                  return (
                    <div key={"ser1_" + index} style={{}}>
                      <div className="row" style={LCss.conditiondiv}>
                        {item.name}
                      </div>
                      {item.child.map((item2, index2) => {
                        return (
                          <div
                            style={LCss.conditioncheck}
                            key={"ser1—_" + index + "_" + index2}
                          >
                            <input
                              style={LCss.checkout}
                              type="checkbox"
                              name={"check" + index + "_" + index2}
                              value="Y"
                              onChange={(ref) =>
                                setCheck(ele, ref, index, index2)
                              }
                              checked={item2.c == "Y" ? true : false}
                            ></input>
                            {item2.name}
                          </div>
                        );
                      })}
                      {item.family != "-2" && (
                        <div className="row" style={{ paddingLeft: "3px" }}>
                          is there a family history of any of the above?
                          <input
                            type="radio"
                            name={"family_" + index}
                            value="Y"
                            onChange={(ref) => setRadio2(ele, ref, index)}
                            checked={item.c == "Y" ? true : false}
                            style={LCss.radio}
                          ></input>
                          {" Yes"}
                          <input
                            type="radio"
                            name={"family_" + index}
                            value="N"
                            onChange={(ref) => setRadio2(ele, ref, index)}
                            checked={item.c == "N" ? true : false}
                            style={LCss.radio}
                          ></input>
                          {" No"}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div
                className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textleft"
                style={{
                  borderLeft: "1px solid #ddd",
                  borderRight: "1px solid #ddd",
                }}
              >
                {ele.state.conditiondata.slice(2, 4).map((item, index) => {
                  let _index = index + 2;
                  return (
                    <div key={"ser2_" + _index} style={{}}>
                      <div className="row" style={LCss.conditiondiv}>
                        {item.name}
                      </div>
                      {item.child.map((item2, index2) => {
                        let q = "";

                        if (typeof item2.q != "undefined") q = item2.q;
                        let q2 = "";

                        if (typeof item2.q2 != "undefined") q2 = item2.q2;
                        return (
                          <Fragment key={"ser2—_" + _index + "_" + index2}>
                            <div style={LCss.conditioncheck}>
                              <input
                                type="checkbox"
                                style={LCss.checkout}
                                name={"check" + _index + "_" + index2}
                                value="Y"
                                onChange={(ref) =>
                                  setCheck(ele, ref, _index, index2)
                                }
                                checked={item2.c == "Y" ? true : false}
                              ></input>
                              {item2.name}
                            </div>
                            {q != "" && (
                              <div
                                style={{
                                  paddingLeft: "5px",
                                  borderBottom:
                                    q2 == "" ? "1px solid #ddd" : "0px",
                                }}
                              >
                                {q}{" "}
                                <input
                                  className="printField"
                                  style={{ height: "25px", with: "380px" }}
                                  value={item2.a}
                                  onChange={(ref) =>
                                    changeField2(ele, ref, _index, index2)
                                  }
                                />
                              </div>
                            )}
                            {q2 != "" && (
                              <div
                                style={{
                                  paddingLeft: "5px",
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                {q2}{" "}
                                <input
                                  className="printField"
                                  style={{ height: "25px", with: "380px" }}
                                  value={item2.a2}
                                  onChange={(ref) =>
                                    changeField3(ele, ref, _index, index2)
                                  }
                                />
                              </div>
                            )}
                          </Fragment>
                        );
                      })}

                      {item.family != "-2" && (
                        <div className="row" style={{ paddingLeft: "2px" }}>
                          is there a family history of any of the above?
                          <input
                            type="radio"
                            name={"family_" + _index}
                            value="Y"
                            onChange={(ref) => setRadio2(ele, ref, _index)}
                            checked={item.c == "Y" ? true : false}
                            style={LCss.radio}
                          ></input>
                          {" Yes"}
                          <input
                            type="radio"
                            name={"family_" + _index}
                            value="N"
                            onChange={(ref) => setRadio2(ele, ref, _index)}
                            checked={item.c == "N" ? true : false}
                            style={LCss.radio}
                          ></input>
                          {" No"}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div
                className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textleft"
                style={{ borderLeft: "0px solid #ddd" }}
              >
                {ele.state.conditiondata.slice(4, 6).map((item, index) => {
                  let _index = index + 4;
                  return (
                    <div key={"ser2_" + _index} style={{}}>
                      <div className="row" style={LCss.conditiondiv}>
                        {item.name}
                      </div>
                      {item.child.map((item2, index2) => {
                        let q = "";
                        if (typeof item2.q != "undefined") q = item2.q;

                        let q2 = "";
                        if (typeof item2.q2 != "undefined") q2 = item2.q2;

                        return (
                          <Fragment key={"ser2—_" + _index + "_" + index2}>
                            <div style={LCss.conditioncheck}>
                              <input
                                type="checkbox"
                                name={"check" + _index + "_" + index2}
                                value="Y"
                                onChange={(ref) =>
                                  setCheck(ele, ref, _index, index2)
                                }
                                checked={item2.c == "Y" ? true : false}
                                style={LCss.checkout}
                              ></input>
                              {item2.name}
                            </div>
                            {q != "" && (
                              <div
                                style={{
                                  paddingLeft: "5px",
                                  borderBottom:
                                    q2 == "" ? "1px solid #ddd" : "0px",
                                }}
                              >
                                {q}{" "}
                                <input
                                  className="printField"
                                  style={{ height: "25px", with: "380px" }}
                                  value={item2.a}
                                  onChange={(ref) =>
                                    changeField2(ele, ref, _index, index2)
                                  }
                                />
                              </div>
                            )}
                            {q2 != "" && (
                              <div
                                style={{
                                  paddingLeft: "5px",
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                {q2}{" "}
                                <input
                                  className="printField"
                                  style={{ height: "25px", with: "380px" }}
                                  value={item2.a2}
                                  onChange={(ref) =>
                                    changeField3(ele, ref, _index, index2)
                                  }
                                />
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                      {item.family != "-2" && (
                        <div className="row" style={{ paddingLeft: "3px" }}>
                          {" "}
                          is there a family history of any of the above?
                          <input
                            type="radio"
                            name={"family_" + _index}
                            value="Y"
                            onChange={(ref) => setRadio2(ele, ref, _index)}
                            checked={item.c == "Y" ? true : false}
                            style={LCss.radio}
                          ></input>
                          {" Yes"}
                          <input
                            type="radio"
                            name={"family_" + _index}
                            value="N"
                            onChange={(ref) => setRadio2(ele, ref, _index)}
                            checked={item.c == "N" ? true : false}
                            style={LCss.radio}
                          ></input>
                          {" No"}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className="row"
              style={{ borderTop: "1px solid #ddd", paddingTop: "8px" }}
            >
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Overall, how is your general health?
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.selfoverall}
                  onChange={(ref) => changeField(ele, ref, "selfoverall")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Primary Care Physician:
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <input
                  className="printField"
                  value={ele.state.physicianname}
                  onChange={(ref) => changeField(ele, ref, "physicianname")}
                />
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                Address:
                <input
                  className="printField"
                  style={{ marginLeft: "15px", width: "360px" }}
                  value={ele.state.physicianaddress}
                  onChange={(ref) => changeField(ele, ref, "physicianaddress")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingTop: "10px" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Current Medications:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.currentmedication}
                  onChange={(ref) => changeField(ele, ref, "currentmedication")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                condition it treats:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.currenttreat}
                  onChange={(ref) => changeField(ele, ref, "currenttreat")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textright">
                Are you currently receiving treatment from another health
                careprofessional?{" "}
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                <input
                  type="radio"
                  name={"havetreatment"}
                  value="Y"
                  onChange={(ref) => setRadio(ele, ref, "havetreatment")}
                  checked={ele.state.havetreatment == "Y" ? true : false}
                  style={LCss.radio}
                ></input>
                {" Yes"}
                <input
                  type="radio"
                  name={"havetreatment"}
                  value="N"
                  onChange={(ref) => setRadio(ele, ref, "havetreatment")}
                  checked={ele.state.havetreatment == "N" ? true : false}
                  style={LCss.radio}
                ></input>
                {" No"}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                If yes, for what?
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textleft">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.whattreatment}
                  onChange={(ref) => changeField(ele, ref, "whattreatment")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Surgery - date:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  className="printField"
                  style={{ width: "360px", marginRight: "10px" }}
                  value={ele.state.surgerydate}
                  onChange={(ref) => changeField(ele, ref, "surgerydate")}
                />
                nature:
                <input
                  className="printField"
                  style={{ width: "360px", marginLeft: "10px" }}
                  value={ele.state.surgerynature}
                  onChange={(ref) => changeField(ele, ref, "surgerynature")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Injury - date:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  className="printField"
                  style={{ width: "360px", marginRight: "10px" }}
                  value={ele.state.injurydate}
                  onChange={(ref) => changeField(ele, ref, "injurydate")}
                />
                nature:
                <input
                  className="printField"
                  style={{ width: "360px", marginLeft: "10px" }}
                  value={ele.state.injurynature}
                  onChange={(ref) => changeField(ele, ref, "injurynature")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textright">
                Do you have any other medical conditions? (e.g.digestive
                conditions, haemophilia, osteoporosis, mentalillness)
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textleft">
                <input
                  type="radio"
                  name={"haveothercondition"}
                  value="Y"
                  onChange={(ref) => setRadio(ele, ref, "haveothercondition")}
                  checked={ele.state.haveothercondition == "Y" ? true : false}
                  style={LCss.radio}
                ></input>
                {" Yes"}
                <input
                  type="radio"
                  name={"haveothercondition"}
                  value="N"
                  onChange={(ref) => setRadio(ele, ref, "haveothercondition")}
                  checked={ele.state.haveothercondition == "N" ? true : false}
                  style={LCss.radio}
                ></input>
                {" No"}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                If yes, for what?
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textleft">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.whatcondition}
                  onChange={(ref) => changeField(ele, ref, "whatcondition")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textright">
                Do you have any internal pins, wires, artificial joints
                orspecial equipment ?
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                <input
                  type="radio"
                  name={"haveequipment"}
                  value="Y"
                  onChange={(ref) => setRadio(ele, ref, "haveequipment")}
                  checked={ele.state.haveequipment == "Y" ? true : false}
                  style={LCss.radio}
                ></input>
                {" Yes"}
                <input
                  type="radio"
                  name={"haveequipment"}
                  value="N"
                  onChange={(ref) => setRadio(ele, ref, "haveequipment")}
                  checked={ele.state.haveequipment == "N" ? true : false}
                  style={LCss.radio}
                ></input>
                {" No"}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                If yes, what?
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textleft">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.whatequipment}
                  onChange={(ref) => changeField(ele, ref, "whatequipment")}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                If yes, where?
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.whereequipment}
                  onChange={(ref) => changeField(ele, ref, "whereequipment")}
                />
              </div>
            </div>

            <div className="row " style={{}}>
              <div
                className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textright"
                style={{ paddingRight: "5px" }}
              >
                What is the reason you are seeking massage therapy? Please
                include the location of any tissue or jointdiscomfort:
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textleft">
                <input
                  className="printField"
                  style={{ width: "360px" }}
                  value={ele.state.massagereason}
                  onChange={(ref) => changeField(ele, ref, "massagereason")}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ paddingTop: "10px", border: "0px solid #ccc" }}
        >
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form"
            style={{ backgroundColor: "#f6f6f6", padding: "10px" }}
          >
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  textIndent: "15px",
                  textAlign: "center",
                }}
              >
                INFORMED CONSENT AND WAIVERTOMASSAGE TREATMENT
              </div>
              <div style={LCss.agree}>
                I understand that the massage therapy I receive by a certain
                person is provided for the purpose of relaxation and relief of
                muscular tension. lf I experience any pain or discomfort during
                the session, I will immediately inform the massage therapist so
                that the pressure and /or strokes may be adjusted to my level of
                comfort. You will be asked to remove your clothes and lie on the
                massage table, you will be fully covered by sheets during the
                treatment, and the only area that RMT will treat will be
                uncovered.
              </div>
              <div style={LCss.agree}>
                I unkierstand and I give consent to my Massage Therapist that
                he/she might have to work on some sensitive area like Chest
                walls, Breast, Buttocks (Gluteal Muscle) & Upper inner thigh(s).
              </div>
              <div style={LCss.agree}>
                The RMT has explained the following to me and I fully understand
                the proposed assessment and/or treatment:
                <div>
                  <span className="dot"></span>
                  The nature of the assessment, including the clinical reason(s)
                  for assessmenl of the above area(s) and the draping methods to
                  be used
                </div>
                <div>
                  <span className="dot"></span>
                  The expected benefits of the assessmnent
                </div>
                <div>
                  <span className="dot"></span>
                  The potential risks of he assessment
                </div>
                <div>
                  <span className="dot"></span>
                  The potential side effects oi ihe assessment
                </div>
                <div>
                  <span className="dot"></span>
                  That consent is voluntary{" "}
                </div>
                <div>
                  <span className="dot"></span>
                  That I can withdraw or alter my consent at any time
                </div>
              </div>
              <div style={LCss.agree}>
                I voluntarily give my informed consent for the assessment and/or
                treatment as discussed and outlined above.
              </div>
              <div className="row " style={{}}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textright">
                  <div
                    style={{
                      float: "right",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",

                        textAlign: "left",
                      }}
                    >
                      <DatePicker
                        className="printField"
                        selected={ele.state.initialdate}
                        onChange={(ref) => setBirthday(ele, ref, "initialdate")}
                        showYearDropdown
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Input date"
                        style={{ float: "left" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      float: "right",
                      paddingRight: "15px",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    Initial:
                  </div>
                </div>
              </div>
              <div style={LCss.agree}>
                I further understand the massage therapy is not a substitute for
                medical examination, diagnosis or medical treatment and I should
                see a physiclan medical specialist for any physical or medical
                ailment of which I am aware. l understand massage therapy should
                not be performed under certain medical conditions and I affirm
                that I have stated all knowm medical conditions and have
                answered all questions accurately.
              </div>
              <div style={LCss.agree}>
                My signature below affirms that I have read and agreed fo the
                foregoing.
              </div>
            </div>
            <div className="row" style={{ paddingTop: "15px", height: "60px" }}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright bold">
                Client Name:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  className="printField"
                  style={{ width: "200px" }}
                  value={ele.state.clientname}
                  onChange={(ref) => changeField(ele, ref, "clientname")}
                />
              </div>
            </div>
            <div className="row" style={{ paddingTop: "15px", height: "auto" }}>
              <div
                className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright bold"
                style={{ height: "50px", lineHeight: "50px" }}
              >
                Client Signature:
              </div>
              <div
                className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
                style={LCss.signdiv}
              >
                <div style={LCss.signcave}>
                  {ele.state.haveimg1 == 0 ? (
                    <SignatureCanvas
                      penColor="black"
                      ref={ele.canvasRef1} //用于调用插件的API
                      canvasProps={{
                        width: 200,
                        height: 50,
                        className: "sigCanvas",
                        style: LCss.canve,
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        float: "center",

                        textAlign: "center",
                        height: "50px",
                        lineHeight: "50px",
                      }}
                    >
                      <img src={ele.state.clientsign} style={{}}></img>
                    </div>
                  )}
                </div>
                <button
                  style={{
                    float: "left",
                    marginLeft: "10px",
                    height: "30px",
                    lineHeight: "30px",
                  }}
                  onClick={() => clearCanv(ele, 1)}
                >
                  {ele.state.haveimg1 == 1 ? "Reset" : "Clear"}
                </button>
              </div>

              <div
                className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft bold"
                style={{
                  height: "50px",
                  verticalAlign: "center",
                  display: "flex", 
                }}
              >
                <div
                  style={{
                    float: "left",
                    paddingRight: "15px",
                    height: "50px",
                    lineHeight: "50px",
                  }}
                >
                  Date:
                </div>
                <div
                  style={{
                    float: "left",
                    height: "50px",
                    lineHeight: "50px",
                  }}
                >
                  <DatePicker
                    className="printField"
                    selected={ele.state.clientsigndate}
                    onChange={(ref) => setBirthday(ele, ref, "clientsigndate")}
                    showYearDropdown
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Input date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!ele.state.isprint && (
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
            style={{ width: "70%", marginTop: "10px", height: "25px" }}
          >
            {ele.state.postresult}
          </div>
        </div>
      )}
      {!ele.state.isprint && (
        <div className="row" style={{ paddingTop: "5px" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <button
              className="submit"
              onClick={() => saveHealForm(ele,1)}
              style={{ background: bookButtonColor,color:"white" }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
}

function setRadio(ele, ref, field) {
  let val = ref.target.value;
  if (field == "hasmassagebefore")
    ele.setState({
      hasmassagebefore: val,
    });
  else if (field == "haspractitionerrefer")
    ele.setState({
      haspractitionerrefer: val,
    });
  else if (field == "havetreatment")
    ele.setState({
      havetreatment: val,
    });
  else if (field == "haveothercondition")
    ele.setState({
      haveothercondition: val,
    });
  else if (field == "haveequipment")
    ele.setState({
      haveequipment: val,
    });
  else if (field == "insur_chiropractic")
    ele.setState({
      insur_chiropractic: val,
    });
  else if (field == "haveallergy")
    ele.setState({
      haveallergy: val,
    });
}

function changeField2(ele, ref, index, index2) {
  let val = ref.target.value;

  let conditiondata = ele.state.conditiondata;
  conditiondata[index].child[index2].a = val;

  ele.setState({
    conditiondata: conditiondata,
  });
}
function changeField3(ele, ref, index, index2) {
  let val = ref.target.value;

  let conditiondata = ele.state.conditiondata;
  conditiondata[index].child[index2].a2 = val;

  ele.setState({
    conditiondata: conditiondata,
  });
}
function setRadio2(ele, ref, index) {
  let val = ref.target.value;

  let conditiondata = ele.state.conditiondata;
  conditiondata[index].c = val;

  ele.setState({
    conditiondata: conditiondata,
  });
}

function setCheck(ele, ref, index, index2) {
  let val = "";
  if (ref.target.checked) {
    val = "Y";
  } else {
    val = "N";
  }
  /*     if (val == "") val = "N";
    if (val == "N") val = "Y";
    else val = "N";
 */
  let conditiondata = ele.state.conditiondata;
  conditiondata[index].child[index2].c = val;

  ele.setState({
    conditiondata: conditiondata,
  });
}
function clearCanv(ele, index) {
  if (index == 1) {
    if (ele.state.haveimg1 == 0) ele.canvasRef1.current.clear();
    ele.setState({
      haveimg1: 0,
    });
  } else if (index == 2) {
    if (ele.state.haveimg2 == 0) ele.canvasRef2.current.clear();
    ele.setState({
      haveimg2: 0,
    });
  } else if (index == 3) {
    if (ele.state.haveimg3 == 0) ele.canvasRef3.current.clear();
    ele.setState({
      haveimg3: 0,
    });
  }
}
function setBirthday(ele, val, field) {
  let _date = val;
  if (val == null) _date = "";
  if (_date != "") {
   /*  let date = new Date(
      val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
    );

    _date = date; */
  }
  if (field == "birthday") {
    ele.setState({
      birthday: _date,
    });
  } else if (field == "patientsigndate") {
    ele.setState({
      patientsigndate: _date,
    });
  } else if (field == "guardiansigndate") {
    ele.setState({
      guardiansigndate: _date,
    });
  } else if (field == "practitionersigndate") {
    ele.setState({
      practitionersigndate: _date,
    });
  } else if (field == "ec_signdate") {
    ele.setState({
      ec_signdate: _date,
    });
  } else if (field == "clientsigndate") {
    ele.setState({
      clientsigndate: _date,
    });
  } else if (field == "initialdate") {
    ele.setState({
      initialdate: _date,
    });
  }
}
function changeField(ele, e, field) {
  let val = e.target.value;
  if (field == "occupation")
    ele.setState({
      occupation: val,
    });
  else if (field == "refaddress")
    ele.setState({
      refaddress: val,
    });
    else if (field == "birthday")
      ele.setState({
        birthday: val,
      });
  else if (field == "address") {
    ele.setState({
      address: val,
    });
  } else if (field == "whattreatment")
    ele.setState({
      whattreatment: val,
    });
  else if (field == "selfoverall")
    ele.setState({
      selfoverall: val,
    });
  else if (field == "physicianname")
    ele.setState({
      physicianname: val,
    });
  else if (field == "physicianaddress")
    ele.setState({
      physicianaddress: val,
    });
  else if (field == "currentmedication")
    ele.setState({
      currentmedication: val,
    });
  else if (field == "currenttreat")
    ele.setState({
      currenttreat: val,
    });
  else if (field == "surgerydate")
    ele.setState({
      surgerydate: val,
    });
  else if (field == "surgerynature")
    ele.setState({
      surgerynature: val,
    });
  else if (field == "injurydate")
    ele.setState({
      injurydate: val,
    });
  else if (field == "injurynature")
    ele.setState({
      injurynature: val,
    });
  else if (field == "whatcondition")
    ele.setState({
      whatcondition: val,
    });
  else if (field == "whatequipment")
    ele.setState({
      whatequipment: val,
    });
  else if (field == "whereequipment")
    ele.setState({
      whereequipment: val,
    });
  else if (field == "massagereason")
    ele.setState({
      massagereason: val,
    });
  else if (field == "clientname")
    ele.setState({
      clientname: val,
    });
  else if (field == "insur_policynum")
    ele.setState({
      insur_policynum: val,
    });
  else if (field == "insur_memberid")
    ele.setState({
      insur_memberid: val,
    });
  else if (field == "seriousothers")
    ele.setState({
      seriousothers: val,
    });
  else if (field == "whatallergy")
    ele.setState({
      whatallergy: val,
    });
  else if (field == "ongoinghealthy")
    ele.setState({
      ongoinghealthy: val,
    });
  else if (field == "longtertreat")
    ele.setState({
      longtertreat: val,
    });
  else if (field == "patientsign")
    ele.setState({
      patientsign: val,
    });
  else if (field == "guardiansign")
    ele.setState({
      guardiansign: val,
    });
  else if (field == "practitionersign")
    ele.setState({
      practitionersign: val,
    });
}
export function saveHealForm(ele,needyz=0) {
  let emptysign =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

  const { myusername, ukey } = ele.props;
  // document.getElementById("result2").style.color = "#000000";

  ele.setState({
    postresult: "Waiting....",
  });
  // document.getElementById("result2").innerHTML = "正在提交....";

  //var gzhnickname = this.state.upgzhnickname;
  //var comname = this.state.upcomname;

  let birthday = ele.state.birthday;
  /* if (birthday != "") {
    birthday =
      birthday.getFullYear() +
      "-" +
      (birthday.getMonth() + 1) +
      "-" +
      birthday.getDate();
  } */

  let clientsigndate = ele.state.clientsigndate;
  if (clientsigndate != "") {
    clientsigndate =
      clientsigndate.getFullYear() +
      "-" +
      (clientsigndate.getMonth() + 1) +
      "-" +
      clientsigndate.getDate();
  }

  let initialdate = ele.state.initialdate;
  if (initialdate != "") {
    initialdate =
      initialdate.getFullYear() +
      "-" +
      (initialdate.getMonth() + 1) +
      "-" +
      initialdate.getDate();
  }
  let clientsign = ele.state.clientsign;
  if (ele.state.haveimg1 == 0) {
    clientsign = ele.canvasRef1.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    if (clientsign == emptysign) clientsign = "";
  }

  ele.setState({
    clientsign: clientsign,
  });
  const postdata = {
    username: ele.state.username,
    // telephone: telephone,
    //  firstname: firstname,
    // lastname: lastname,
    //  address: this.state.address,

    occupation: ele.state.occupation,
    hasmassagebefore: ele.state.hasmassagebefore,
    haspractitionerrefer: ele.state.haspractitionerrefer,
    refaddress: ele.state.refaddress,

    birthday: birthday,
    conditiondata: ele.state.conditiondata,
    selfoverall: ele.state.selfoverall,
    physicianname: ele.state.physicianname,
    physicianaddress: ele.state.physicianaddress,

    currentmedication: ele.state.currentmedication,
    currenttreat: ele.state.currenttreat,
    havetreatment: ele.state.havetreatment,
    whattreatment: ele.state.whattreatment,
    surgerydate: ele.state.surgerydate,
    surgerynature: ele.state.surgerynature,
    injurydate: ele.state.injurydate,
    injurynature: ele.state.injurynature,
    haveothercondition: ele.state.haveothercondition,
    whatcondition: ele.state.whatcondition,

    haveequipment: ele.state.haveequipment,
    whatequipment: ele.state.whatequipment,
    whereequipment: ele.state.whereequipment,
    massagereason: ele.state.massagereason,
    initialdate: initialdate,
    date1: ele.state.date1,
    date2: ele.state.date2,
    date3: ele.state.date3,
    date3: ele.state.date3,
    date4: ele.state.date4,
    clientname: ele.state.clientname,

    clientsign: clientsign,
    clientsigndate: clientsigndate,
  };
  //console.log(postdata);
  // return;
  const this2 = ele;
  var error = "";
  //if (ele.state.telephone === "") error = "Please fill in telephone";
  //else if (ele.state.firstname == "") error = "Please fill in firstname";
  //else if (ele.state.lastname == "") error = "Please fill in lastname";
  //if (ele.state.address == "") error = "Please fill in address";
  if (clientsign == "") error = "Please signature";
  // else if (clientsigndate == "") error = "Please fill in signature date";
  else if (ele.state.clientname == "") error = "Please fill in client name";
  //document.getElementById("result2").style.color = "red";
  if (error !== ""&&needyz==1) {
    ele.setState({
      postresult: error,
    });
    return;
  }
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=savehealth&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
      //  console.log(res);
        var r = res.r;
        var msg = res.s;

        if (r === "1") {
          //  this.postresult = "登陆成功！";
          MyToastShow(
            this2,
            2,
            "Successfully!",
            200,
            120,
            "success",
            "addemployee"
          );
          this2.setState({
            postresult: "",
          });
          //if (myusername == this2.state.username)
          //this2.props.history.push("/user");
        } else {
          //document.getElementById("result2").style.color = "red";
          MyToastShow(this2, 2, msg, 200, 120, "fail", "addemployee");
          this2.setState({
            postresult: "",
          });

          //  document.getElementById("result2").innerHTML = msg;
        }

        //  document.getElementById("result2").innerHTML = msg;
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  
}
