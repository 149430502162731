import { AJAROOT, bookButtonColor } from "@/common/gdata/global.js";
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import errorPic from "../statics/error.png";
import gouPic from "../statics/gou.png";
import { Fragment } from "react";
import { MyToast, MyToastShow,isEmpty } from "@/jscript/common.js";
import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import "@/css/react-datepicker.css";
import "@/css/user.css";
import DatePicker from "react-datepicker";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
};
export function BasicForm(ele) {
  return (
    <div
      className="row"
      style={{
        paddingTop: "10px",
      }}
    >
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Account:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            {ele.state.username}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            * First Name:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              value={ele.state.firstname}
              onChange={(ref) => changeField(ele, ref, "firstname")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            * Last Name:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              value={ele.state.lastname}
              onChange={(ref) => changeField(ele, ref, "lastname")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            * Telephone:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              value={ele.state.telephone}
              onChange={(ref) => changeField(ele, ref, "telephone")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            * Address:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              style={{ width: "360px" }}
              value={ele.state.address}
              onChange={(ref) => changeField(ele, ref, "address")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            City:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              value={ele.state.city}
              onChange={(ref) => changeField(ele, ref, "city")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Province:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              value={ele.state.province}
              onChange={(ref) => changeField(ele, ref, "province")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Postal Code:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              value={ele.state.postal}
              onChange={(ref) => changeField(ele, ref, "postal")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Email:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"

              value={ele.state.email}
              onChange={(ref) => changeField(ele, ref, "email")}
            />


          </div>
        </div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Date of Birth:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            {/* <DatePicker
              selected={ele.state.birthday}
              onChange={(ref) => setBirthday(ele, ref, "birthday")}
              showYearDropdown
              dateFormat="yyyy-MM-dd"
              placeholderText="Input birthday"
              className="printField"
            /> */}
            <input
                className="printField"
                value={ele.state.birthday}
                placeholder="YYYY/MM/DD"
                onChange={(ref) => changeField(ele, ref, "birthday")}
              />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Have Insurance:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              type="checkbox"
              name="haveinsurance"
              value="Y"
              onChange={(ref) => changeField(ele, ref, "haveinsurance")}
              checked={ele.state.haveinsurance == 1 ? true : false}
              style={{
                width: "18px",
                height: "18px",
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Password:
          </div>
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <input
              className="printField"
              onChange={(ref) => changeField(ele, ref, "password")}
            />{" "}
            (Will not update when left blank)
          </div>
        </div>

        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
            style={{ width: "70%", marginTop: "3px", height: "25px" }}
          >
            {ele.state.postresult}
          </div>
        </div>
        {!ele.state.isprint && (
          <div className="row" style={{ paddingTop: "5px" }}>
             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
             

           {/*    <span
               
                onClick={() => sendEclaimEmail(ele)}
               style={{ width:"150px" }}
              >
                Send Eclaim Email
              </span> */}


              <button
                className="submit"
                onClick={() => savebasic(ele,1)}
               style={{ width:"200px",background: bookButtonColor,color:"white" }}
              >
                Save Basic Info
              </button>

              <button
                className="submit"
                onClick={() => savebasic(ele,100)}
               style={{ marginLeft:"15px",width:"300px",background: bookButtonColor,color:"white" }}
              >
               Save and Send Eclaim Email
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export function SpaForm(ele) {
  if (ele.state.haveimg3 == null) return <></>;
  return (
    <div>
      <div className="row">
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
          style={{ fontSize: "16px", marginTop: "10px" }}
        >
          * Required Information
        </div>
      </div>
      <div
        className="row"
        style={{ border: "1px solid #ddd", paddingTop: "10px" }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
          <div className="row ">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Emergency Contact Name:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <input
                className="printField"
                value={ele.state.contactname}
                onChange={(ref) => changeField(ele, ref, "contactname")}
              />
            </div>

            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Phone Number:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <input
                className="printField"
                style={{ }}
                value={ele.state.contacttel}
                onChange={(ref) => changeField(ele, ref, "contacttel")}
              />
            </div>

          {/*   <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
              
              <input
                className="printField"
                style={{ marginLeft: "15px" }}
                value={ele.state.contacttel}
                onChange={(ref) => changeField(ele, ref, "contacttel")}
              />
            </div> */}
          </div>

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Family Dr.:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <input
                className="printField"
                value={ele.state.familydoc}
                onChange={(ref) => changeField(ele, ref, "familydoc")}
              />
            </div>


            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Phone Number:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <input
                className="printField"
                style={{}}
                value={ele.state.familydoctel}
                onChange={(ref) => changeField(ele, ref, "familydoctel")}
              />
            </div>

          {/*   <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
              Phone Number:
              <input
                className="printField"
                style={{ marginLeft: "15px" }}
                value={ele.state.familydoctel}
                onChange={(ref) => changeField(ele, ref, "familydoctel")}
              />
            </div> */}
          </div>

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Family Doctor Address:
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <input
                className="printField"
                style={{ width: "360px" }}
                value={ele.state.familydocaddress}
                onChange={(ref) => changeField(ele, ref, "familydocaddress")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
          style={{ fontSize: "16px", marginTop: "10px" }}
        >
          Insurance Coverage Information
        </div>
      </div>
      <div
        className="row"
        style={{ border: "1px solid #ddd", paddingTop: "10px" }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
          <div className="row ">
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Massage Therapy:
            </div>
            <div
              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 "
              style={{
                display: "flex",

                alignContent: "center",
              }}
            >
              <input
                type="checkbox"
                name="insur_massage"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "insur_massage")}
                checked={ele.state.insur_massage == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              ></input>
              {" Yes"}
            </div>

            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Acupuncture:
            </div>
            <div
              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 "
              style={{
                display: "flex",

                alignContent: "center",
              }}
            >
              <input
                type="checkbox"
                name="insur_acupuncture"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "insur_acupuncture")}
                checked={ele.state.insur_acupuncture == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              ></input>
              {" Yes"}
            </div>

            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Osteopath:
            </div>
            <div
              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 "
              style={{
                display: "flex",

                alignContent: "center",
              }}
            >
              <input
                type="checkbox"
                name="insur_osteopath"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "insur_osteopath")}
                checked={ele.state.insur_osteopath == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              ></input>
              {" Yes"}
            </div>
          </div>

          <div className="row ">
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Physiotherapy:
            </div>
            <div
              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 "
              style={{
                display: "flex",

                alignContent: "center",
              }}
            >
              <input
                type="checkbox"
                name="insur_physiotherapy"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "insur_physiotherapy")}
                checked={ele.state.insur_physiotherapy == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              ></input>
              {" Yes"}
            </div>

            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Chiropractic:
            </div>
            <div
              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 "
              style={{
                display: "flex",

                alignContent: "center",
              }}
            >
              <input
                type="checkbox"
                name="insur_chiropractic"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "insur_chiropractic")}
                checked={ele.state.insur_chiropractic == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              ></input>
              {" Yes"}
            </div>

            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Naturopath:
            </div>
            <div
              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 "
              style={{
                display: "flex",

                alignContent: "center",
              }}
            >
              <input
                type="checkbox"
                name="insur_naturopath"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "insur_naturopath")}
                checked={ele.state.insur_naturopath == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              ></input>
              {" Yes"}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Name of your insurance company:
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <input
                className="printField"
                style={{ width: "360px" }}
                value={ele.state.insur_company}
                onChange={(ref) => changeField(ele, ref, "insur_company")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Insurance Policy #:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <input
                className="printField"
                value={ele.state.insur_policynum}
                onChange={(ref) => changeField(ele, ref, "insur_policynum")}
              />
            </div>

            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Member ID #:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <input
                className="printField"
                style={{ }}
                value={ele.state.insur_memberid}
                onChange={(ref) => changeField(ele, ref, "insur_memberid")}
              />
            </div>
         {/*    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
              Member ID #:
              <input
                className="printField"
                style={{ marginLeft: "15px" }}
                value={ele.state.insur_memberid}
                onChange={(ref) => changeField(ele, ref, "insur_memberid")}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
          style={{ fontSize: "16px", marginTop: "10px" }}
        >
          Check if applicable to you
        </div>
      </div>
      <div
        className="row"
        style={{
          border: "1px solid #ddd",
          paddingTop: "10px",
          paddingLeft: "10px",
        }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
          <div className="row">
            {ele.state.seriousdata.map((item, index) => {
              return (
                <div
                  key={"ser" + index}
                  style={{
                    display: "flex",
                    width: "160px",
                    float: "left",
                    padding: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    name="ser"
                    value="Y"
                    onChange={() => setSerData(ele, index)}
                    checked={item.a == 1 ? true : false}
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  ></input>
                  {item.name}
                </div>
              );
            })}
          </div>

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Others
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <input
                className="printField"
                style={{ width: "360px" }}
                value={ele.state.seriousothers}
                onChange={(ref) => changeField(ele, ref, "seriousothers")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ paddingTop: "10px" }}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Do you have any Allergy?
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <div
                style={{
                  display: "flex",
                  width: "160px",
                  float: "left",
                  padding: "5px",
                }}
              >
                <input
                  type="checkbox"
                  name="insur_chiropractic"
                  value="Y"
                  onChange={(ref) => setCheck(ele, ref, "haveallergy")}
                  checked={ele.state.haveallergy == "Y" ? true : false}
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                ></input>
                {" Yes"}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              If Yes, what are you allergic to?
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <input
                className="printField"
                style={{ width: "360px" }}
                value={ele.state.whatallergy}
                onChange={(ref) => changeField(ele, ref, "whatallergy")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Ongoing healthy condition(s):
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <input
                className="printField"
                style={{ width: "360px" }}
                value={ele.state.ongoinghealthy}
                onChange={(ref) => changeField(ele, ref, "ongoinghealthy")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Long ter treament(s):
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <input
                className="printField"
                style={{ width: "360px" }}
                value={ele.state.longtertreat}
                onChange={(ref) => changeField(ele, ref, "longtertreat")}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          paddingTop: "10px",
          paddingLeft: "10px",
          border: "0px solid #ccc",
        }}
      >
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form"
          style={{ backgroundColor: "#f6f6f6", padding: "10px" }}
        >
          <div>
            <div style={{ fontWeight: "bold", textIndent: "15px" }}>
              I, or the persen listed below, have discusse with my Traditional
              Chinese Medicine Practitioner or Acupuncturist the specifics of my
              assessment or treatment and understand the nature, risks and
              reasons for this procedure. I voluntarily consent to Traditiona
              Chinese Medicine/Acupuncture and understand that I may withdraw my
              consent and halt my participation at any time.
            </div>
            <div style={LCss.agree}>
              1. I understanid that some of the techniques used under the scope
              of Traditional Chinese Medicine include the use of sterile,
              sinele-use needles to penetrate the skin. Additional treatment
              methods can include, but are not limited to: acupurtcture,
              acupressure, the electrical stimulation of needles, cupping or
              moxibustion gua sha, and Tuina. Before any of these procedures are
              performed, my practitioner will discuss my treatment options and
              only proceed if my consent is given.
            </div>
            <div style={LCss.agree}>
              2. My practitioner has informed me of the risks and symptoms of
              treatments, which can include, but are not limited to: slight
              pain, light-headedness or nausea, soreness, bruising, bleeding or
              discoloration of the skin, and the possibility of other unforeseen
              risks, I freely accept the risks involved with my procedure.
            </div>
            <div style={LCss.agree}>
              3. I wil inform my practitioner if currently have or develop any
              major health issues, if I suffer from any type of major bleeding
              disorder(s). of if I use a pacemaker.
            </div>
            <div style={LCss.agree}>
              4. I understand that I must let my practitioner know if I am
              carrying, or believe to have any infectious agents, including but
              not limited to HIV, TB and Hepatitis. In some cases where
              cross-infection is high, my practitioner may withhold treatment.
            </div>
            <div style={LCss.agree}>
              5. I understand that there are no guarantees for the results of my
              treatments. Traditional Chinese Medicine does not often provide an
              instant cure. The leneth of my treatment depends on the severity
              of my condition. In some cases my symptoms may temporarily worsen
              before they begin to improve.
            </div>
            <div style={LCss.agree}>
              6. I understand that the fees charged for my treatment are not
              covered under OHOP and must be covered in full by myself or
              through third party insurance. I am responsible for the full and
              prompt payment after services have been rendered.
            </div>
            <div style={LCss.agree}>
              7. I bave discussed the content of this form with my practitioner.
              I acknowledge that I have asked any questions I may have and
              received answers I understand. By signing this form, I give my
              informed consent for Traditional Chinese Medicine treatmments.
            </div>
            <div style={LCss.agree}>
              8. I understand and is aware that the clinic is Traditional
              Chinese Medical Clinic and we offer natural assessment and
              treatrnent, such as Acupuncture, Herbs, Massage and cupping etc.
            </div>
            <div style={LCss.agree}>
              9. I accept the responsibility for decisions the make about the
              personal care and treatment.
            </div>
            <div style={LCss.agree}>
              10. Ask practitianers or health-care personnel if you have any
              doubts or questions.
            </div>
            <div style={LCss.agree}>11. I bave read the policies above. </div>
            <div style={{ paddingTop: "15px" }}>
              Hereby give consent to treatment, with the understanding that are
              written and or verbal consent will beon going
            </div>
          </div>
          <div className="row" style={{ paddingTop: "15px", height: "auto" }}>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright bold"
              style={{ height: "50px", lineHeight: "50px" }}
            >
              Patient Signature:
            </div>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
              style={LCss.signdiv}
            >
              <div style={LCss.signcave}>
                {ele.state.haveimg1 == 0 ? (
                  <SignatureCanvas
                    penColor="black"
                    ref={ele.canvasRef1} //用于调用插件的API
                    canvasProps={{
                      width: 200,
                      height: 50,
                      className: "sigCanvas",
                      style: LCss.canve,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      float: "center",

                      textAlign: "center",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    <img src={ele.state.patientsign} style={{}}></img>
                  </div>
                )}
              </div>
              {!ele.state.isprint && (
                <button
                  style={{
                    float: "left",
                    marginLeft: "10px",
                    height: "30px",
                    lineHeight: "30px",
                  }}
                  onClick={() => clearCanv(ele, 1)}
                >
                  {ele.state.haveimg1 == 1 ? "Reset" : "Clear"}
                </button>
              )}
            </div>

            <div
              className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft bold"
              style={{
                height: "50px",
                verticalAlign: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  float: "left",
                  paddingRight: "15px",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                Date:
              </div>
              <div
                style={{
                  float: "left",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                <DatePicker
                  selected={ele.state.patientsigndate}
                  onChange={(ref) => setBirthday(ele, ref, "patientsigndate")}
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Input date"
                  className="printField"
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "15px", height: "auto",}}>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright bold"
              style={{ height: "auto", lineHeight: "50px"  }}
            >
              Signature of the client or guardian (if children or elderly):
            </div>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
              style={LCss.signdiv}
            >
              <div style={LCss.signcave}>
                {ele.state.haveimg2 == 0 ? (
                  <SignatureCanvas
                    penColor="black"
                    ref={ele.canvasRef2} //用于调用插件的API
                    canvasProps={{
                      width: 200,
                      height: 50,
                      className: "sigCanvas",
                      style: LCss.canve,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      float: "center",

                      textAlign: "center",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    <img src={ele.state.guardiansign} style={{}}></img>
                  </div>
                )}
              </div>
              {!ele.state.isprint && (
                <button
                  style={{
                    float: "left",
                    marginLeft: "10px",
                    height: "30px",
                    lineHeight: "30px",
                  }}
                  onClick={() => clearCanv(ele, 2)}
                >
                  {ele.state.haveimg2 == 1 ? "Reset" : "Clear"}
                </button>
              )}
            </div>

            <div
              className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft bold"
              style={{
                height: "50px",
                verticalAlign: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  float: "left",
                  paddingRight: "15px",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                Date:
              </div>
              <div
                style={{
                  float: "left",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                <DatePicker
                  selected={ele.state.guardiansigndate}
                  onChange={(ref) => setBirthday(ele, ref, "guardiansigndate")}
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Input date"
                  className="printField"
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "15px", height: "auto" }}>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright bold"
              style={{ height: "50px", lineHeight: "50px" }}
            >
              Practitioner Signature:
            </div>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
              style={LCss.signdiv}
            >
              <div style={LCss.signcave}>
                {ele.state.haveimg3 == 0 ? (
                  <SignatureCanvas
                    penColor="black"
                    ref={ele.canvasRef3} //用于调用插件的API
                    canvasProps={{
                      width: 200,
                      height: 50,
                      className: "sigCanvas",
                      style: LCss.canve,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      float: "center",

                      textAlign: "center",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    <img src={ele.state.practitionersign} style={{}}></img>
                  </div>
                )}
              </div>
              {!ele.state.isprint && (
                <button
                  style={{
                    float: "left",
                    marginLeft: "10px",
                    height: "30px",
                    lineHeight: "30px",
                  }}
                  onClick={() => clearCanv(ele, 3)}
                >
                  {ele.state.haveimg3 == 1 ? "Reset" : "Clear"}
                </button>
              )}
            </div>

            <div
              className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft bold"
              style={{
                height: "50px",
                verticalAlign: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  float: "left",
                  paddingRight: "15px",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                Date:
              </div>
              <div
                style={{
                  float: "left",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                <DatePicker
                  selected={ele.state.practitionersigndate}
                  onChange={(ref) =>
                    setBirthday(ele, ref, "practitionersigndate")
                  }
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Input date"
                  className="printField"
                />
              </div>
            </div>
          </div>
          {!ele.state.isprint && (
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                style={{ width: "70%", marginTop: "3px", height: "25px" }}
              >
                {ele.state.postresult}
              </div>
            </div>
          )}
          {!ele.state.isprint && (
            <div className="row" style={{ paddingTop: "5px" }}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
                <button
                  className="submit"
                  onClick={() => SaveSpaForm(ele,1)}
                  style={{ background: bookButtonColor,color:"white" }}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function setSerData(ele, index) {
  let serviousdata = ele.state.seriousdata;
  let val = serviousdata[index].a;
  val = (val + 1) % 2;
  serviousdata[index].a = val;
  ele.setState({
    seriousdata: serviousdata,
  });
}

function setCheck(ele, ref, field) {
  let val = "";
  if (ref.target.checked) {
    val = "Y";
  } else {
    val = "N";
  }

  if (field == "insur_massage")
    ele.setState({
      insur_massage: val,
    });
  else if (field == "insur_acupuncture")
    ele.setState({
      insur_acupuncture: val,
    });
  else if (field == "insur_acupuncture")
    ele.setState({
      insur_acupuncture: val,
    });
  else if (field == "insur_osteopath")
    ele.setState({
      insur_osteopath: val,
    });
  else if (field == "insur_physiotherapy")
    ele.setState({
      insur_physiotherapy: val,
    });
  else if (field == "insur_chiropractic")
    ele.setState({
      insur_chiropractic: val,
    });
  else if (field == "haveallergy")
    ele.setState({
      haveallergy: val,
    });
}
function clearCanv(ele, index) {
  if (index == 1) {
    if (ele.state.haveimg1 == 0) ele.canvasRef1.current.clear();
    ele.setState({
      haveimg1: 0,
    });
  } else if (index == 2) {
    if (ele.state.haveimg2 == 0) ele.canvasRef2.current.clear();
    ele.setState({
      haveimg2: 0,
    });
  } else if (index == 3) {
    if (ele.state.haveimg3 == 0) ele.canvasRef3.current.clear();
    ele.setState({
      haveimg3: 0,
    });
  }
}
function setBirthday(ele, val, field) {
  let _date = val;
  if (val == null) _date = "";
  if (_date != "") {
  /*   let date = new Date(
      val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
    );

    _date = date; */
  }
  if (field == "birthday") {
    ele.setState({
      birthday: _date,
    });
  } else if (field == "patientsigndate") {
    ele.setState({
      patientsigndate: _date,
    });
  } else if (field == "guardiansigndate") {
    ele.setState({
      guardiansigndate: _date,
    });
  } else if (field == "practitionersigndate") {
    ele.setState({
      practitionersigndate: _date,
    });
  }
}
function changeField(ele, e, field) {
  let val = e.target.value;
  if (field == "username")
    ele.setState({
      username: val,
    });
    else if (field == "birthday")
      ele.setState({
        birthday: val,
      });
    
  else if (field == "firstname")
    ele.setState({
      firstname: val,
    });
  else if (field == "lastname")
    ele.setState({
      lastname: val,
    });
  else if (field == "password")
    ele.setState({
      password: val,
    });
  else if (field == "telephone")
    ele.setState({
      telephone: val,
    });
  else if (field == "email")
    ele.setState({
      email: val,
    });
  else if (field == "address") {
    ele.setState({
      address: val,
    });
  } else if (field == "city")
    ele.setState({
      city: val,
    });
  else if (field == "province")
    ele.setState({
      province: val,
    });
  else if (field == "postal")
    ele.setState({
      postal: val,
    });
  else if (field == "contactname")
    ele.setState({
      contactname: val,
    });
  else if (field == "contacttel")
    ele.setState({
      contacttel: val,
    });
  else if (field == "familydoc")
    ele.setState({
      familydoc: val,
    });
  else if (field == "familydoctel")
    ele.setState({
      familydoctel: val,
    });
  else if (field == "familydocaddress")
    ele.setState({
      familydocaddress: val,
    });
  else if (field == "insur_massage")
    ele.setState({
      insur_massage: val,
    });
  else if (field == "insur_acupuncture")
    ele.setState({
      insur_acupuncture: val,
    });
  else if (field == "insur_osteopath")
    ele.setState({
      insur_osteopath: val,
    });
  else if (field == "insur_physiotherapy")
    ele.setState({
      insur_physiotherapy: val,
    });
  else if (field == "insur_chiropractic")
    ele.setState({
      insur_chiropractic: val,
    });
  else if (field == "insur_naturopath")
    ele.setState({
      insur_naturopath: val,
    });
  else if (field == "insur_company")
    ele.setState({
      insur_company: val,
    });
  else if (field == "insur_policynum")
    ele.setState({
      insur_policynum: val,
    });
  else if (field == "insur_memberid")
    ele.setState({
      insur_memberid: val,
    });
  else if (field == "seriousothers")
    ele.setState({
      seriousothers: val,
    });
  else if (field == "whatallergy")
    ele.setState({
      whatallergy: val,
    });
  else if (field == "ongoinghealthy")
    ele.setState({
      ongoinghealthy: val,
    });
  else if (field == "longtertreat")
    ele.setState({
      longtertreat: val,
    });
  else if (field == "patientsign")
    ele.setState({
      patientsign: val,
    });
  else if (field == "guardiansign")
    ele.setState({
      guardiansign: val,
    });
  else if (field == "practitionersign")
    ele.setState({
      practitionersign: val,
    });
  else if (field == "password")
    ele.setState({
      password: val,
    });
  else if (field == "haveinsurance") {
    val = "";
    if (e.target.checked) {
      val = 1;
    } else {
      val = 0;
    }
    ele.setState({
      haveinsurance: val,
    });
  }
}

export function savebasic(ele,needyz=0) {
  const { myusername, ukey } = ele.props;

  var telephone = ele.state.telephone;
  var firstname = ele.state.firstname;
  var lastname = ele.state.lastname;

  var address = ele.state.address;
  var password = ele.state.password;
  if (password == null) password = "";

  var city = ele.state.city;
  if (city == null) city = "";

  var province = ele.state.province;
  if (province == null) province = "";

  var postal = ele.state.postal;
  if (postal == null) postal = "";
  var email = ele.state.email;
  if (email == null) email = "";

  var haveinsurance = ele.state.haveinsurance;
  if (haveinsurance == null) haveinsurance = 1;

  let birthday = ele.state.birthday;

  if (birthday == null) birthday = "";
/*   if (birthday != "") {
    birthday =
      birthday.getFullYear() +
      "-" +
      (birthday.getMonth() + 1) +
      "-" +
      birthday.getDate();
  } */

  const postdata = {
    username: ele.state.username,
    telephone: telephone,
    firstname: firstname,
    lastname: lastname,
    password: password,
    address: ele.state.address,
    city: city,
    province: province,
    postal: postal,
    email: email,
    address: address,
    birthday: birthday,
    haveinsurance: haveinsurance,
    sendeclaimemail:needyz==100?"Y":"N"
  };

  const this2 = ele;
  var error = "";


  if (needyz==1) {

    if (ele.state.telephone === "") error = "Please fill in telephone";
    else if (ele.state.firstname == "") error = "Please fill in firstname";
    else if (ele.state.lastname == "") error = "Please fill in lastname";
    else if (ele.state.address == "") error = "Please fill in address";
    if(error!="")MyToastShow(this2, 2, error, 200, 120, "fail", "addemployee");
  } 
  else if (needyz==100) {//email
    
    if (ele.state.email === "") error = "Please fill in email";
    else if (isEmpty(ele.state.password)) error = "Please fill in password";
    else if (ele.state.haveinsurance ===0) error = "Please select insurance";
    

    if(error!="")MyToastShow(this2, 2, error, 200, 120, "fail", "addemployee");
  } 
  if(error=="") {
 
  let posturl= AJAROOT +
        "/postaja/savexx.aspx?action=upinfo&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "";
  
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
       posturl,
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
    
        var r = res.r;
        var msg = res.s;

        if (r === "1") {
          MyToastShow(
            this2,
            2,
            msg,
            200,
            120,
            "success",
            "addemployee"
          );
        } else {
          MyToastShow(this2, 2, msg, 200, 120, "fail", "addemployee");
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
}

export function getSpaData(ele, username) {
  const { myusername, ukey } = ele.props;
  if (username == "") username = myusername;
  var geturl =
    AJAROOT +
    "/getaja/user.aspx?u=" +
    myusername +
    "&username=" +
    username +
    "&ukey=" +
    encodeURIComponent(ukey) +
    "";
  console.log(geturl);
  axios.get(geturl).then((res) => {
    const result = res.data;
    console.log(result);
    // this.setState({}); /**/
    let haveimg1 = 0;
    let haveimg2 = 0;
    let haveimg3 = 0;

    var firstname = result.firstname;
    var lastname = result.lastname;
    let birthday = result.birthday;
    if (birthday == null) birthday = "";
    if (birthday != "") {
    //  birthday = new Date(birthday);
    }

    let patientsigndate = result.patientsigndate;
    if (patientsigndate == null) patientsigndate = "";
    if (patientsigndate != "") {
      patientsigndate = new Date(patientsigndate);
    }

    let guardiansigndate = result.guardiansigndate;
    if (guardiansigndate == null) guardiansigndate = "";
    if (guardiansigndate != "") {
      guardiansigndate = new Date(guardiansigndate);
    }

    let practitionersigndate = result.practitionersigndate;
    if (practitionersigndate == null) practitionersigndate = "";
    if (practitionersigndate != "") {
      practitionersigndate = new Date(practitionersigndate);
    }

    let patientsign = result.patientsign;
    if (patientsign == null) patientsign = "";
    if (patientsign != "") {
      haveimg1 = 1;
    }

    let guardiansign = result.guardiansign;
    if (guardiansign == null) guardiansign = "";
    if (guardiansign != "") {
      haveimg2 = 1;
    }

    let practitionersign = result.practitionersign;
    if (practitionersign == null) practitionersign = "";
    if (practitionersign != "") {
      haveimg3 = 1;
    }

    //this.canvasRef1.current.fromDataURL(patientsign);
    // this.canvasRef2.current.fromDataURL(guardiansign);
    // this.canvasRef3.current.fromDataURL(practitionersign);
    // if (zsxm === "") zsxm = username;
    ele.setState({
      firstname: firstname,
      lastname: lastname,
      telephone: result.telephone,
      address: result.address,
      city: result.city,
      province: result.province,
      postal: result.postal,
      birthday: birthday,
      email: result.email,
      haveinsurance: result.haveinsurance,
      contactname: result.contactname,
      contacttel: result.contacttel,
      familydoc: result.familydoc,
      familydoctel: result.familydoctel,
      familydocaddress: result.familydocaddress,
      insur_massage: result.insur_massage,
      insur_acupuncture: result.insur_acupuncture,
      insur_osteopath: result.insur_osteopath,
      insur_physiotherapy: result.insur_physiotherapy,
      insur_chiropractic: result.insur_chiropractic,
      insur_naturopath: result.insur_naturopath,
      insur_company: result.insur_company,
      insur_policynum: result.insur_policynum,
      insur_memberid: result.insur_memberid,
      seriousdata: result.seriousdata,
      seriousothers: result.seriousothers,
      haveallergy: result.haveallergy,
      whatallergy: result.whatallergy,
      ongoinghealthy: result.ongoinghealthy,
      longtertreat: result.longtertreat,

      patientsign: patientsign,
      patientsigndate: patientsigndate,
      guardiansign: guardiansign,
      guardiansigndate: guardiansigndate,
      practitionersign: practitionersign,
      practitionersigndate: practitionersigndate,
      haveimg1: haveimg1,
      haveimg2: haveimg2,
      haveimg3: haveimg3,
    });
  });
}



export function sendEclaimEmail_del(ele) {
let that=this;
  const { myusername, ukey } = ele.props;
  const postdata = {
    username: ele.state.username,
    email:ele.state.email,
    password:ele.state.password
    
  };



 var error = "";
 if (ele.state.email === "") error = "Please fill in email";
 else if (ele.state.password == "") error = "Please fill in password";
 
 let this2=ele;

 if (error !== "") {
   MyToastShow(this2, 2, error, 200, 120, "fail", "addemployee");
 } else {
  let posturl= AJAROOT +
        "/postaja/savexx.aspx?action=sendeclaimemail&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "";
  
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
       posturl,
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
       
        var r = res.r;
        var msg = res.s;

        if (r === "1") {
          MyToastShow(
            this2,
            2,
            "Successfully!",
            200,
            120,
            "success",
            ""
          );
        } else {
          MyToastShow(this2, 2, msg, 200, 120, "fail", "addemployee");
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
    }
}


export function SaveSpaForm(ele,needyz=0) {
  let emptysign =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

  const { myusername, ukey } = ele.props;
  // document.getElementById("result2").style.color = "#000000";

  ele.setState({
    postresult: "Waiting....",
  });
  // document.getElementById("result2").innerHTML = "正在提交....";
  var telephone = ele.state.telephone;
  var firstname = ele.state.firstname;
  var lastname = ele.state.lastname;
  //var gzhnickname = this.state.upgzhnickname;
  //var comname = this.state.upcomname;

  let birthday = ele.state.birthday;
/*   if (birthday != "") {
    birthday =
      birthday.getFullYear() +
      "-" +
      (birthday.getMonth() + 1) +
      "-" +
      birthday.getDate();
  } */
  var contactname = ele.state.contactname;
  var contacttel = ele.state.contacttel;
  var familydoc = ele.state.familydoc;
  var familydoctel = ele.state.familydoctel;
  var familydocaddress = ele.state.familydocaddress;

  let guardiansigndate = ele.state.guardiansigndate;
  if (guardiansigndate != "") {
    guardiansigndate =
      guardiansigndate.getFullYear() +
      "-" +
      (guardiansigndate.getMonth() + 1) +
      "-" +
      guardiansigndate.getDate();
  }

  let patientsigndate = ele.state.patientsigndate;
  if (patientsigndate != "") {
    patientsigndate =
      patientsigndate.getFullYear() +
      "-" +
      (patientsigndate.getMonth() + 1) +
      "-" +
      patientsigndate.getDate();
  }

  let practitionersigndate = ele.state.practitionersigndate;
  if (practitionersigndate != "") {
    practitionersigndate =
      practitionersigndate.getFullYear() +
      "-" +
      (practitionersigndate.getMonth() + 1) +
      "-" +
      practitionersigndate.getDate();
  }
  let patientsign = ele.state.patientsign;
  if (ele.state.haveimg1 == 0) {
    patientsign = ele.canvasRef1.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    if (patientsign == emptysign) patientsign = "";
  }

  let guardiansign = ele.state.guardiansign;
  if (ele.state.haveimg2 == 0) {
    guardiansign = ele.canvasRef2.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    if (guardiansign == emptysign) guardiansign = "";
  }

  let practitionersign = ele.state.practitionersign;
  if (ele.state.haveimg3 == 0) {
    practitionersign = ele.canvasRef3.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    if (practitionersign == emptysign) practitionersign = "";
  }
  ele.setState({
    patientsign: patientsign,
    guardiansign: guardiansign,
    practitionersign: practitionersign,
  });
  const postdata = {
    username: ele.state.username,
    telephone: telephone,
    firstname: firstname,
    lastname: lastname,
    address: ele.state.address,
    city: ele.state.city,
    province: ele.state.province,
    postal: ele.state.postal,
    email: ele.state.email,

    birthday: birthday,
    contactname: contactname,
    contacttel: contacttel,
    familydoc: familydoc,
    familydoctel: familydoctel,
    familydocaddress: familydocaddress,
    insur_massage: ele.state.insur_massage,
    insur_acupuncture: ele.state.insur_acupuncture,
    insur_osteopath: ele.state.insur_osteopath,
    insur_physiotherapy: ele.state.insur_physiotherapy,
    insur_chiropractic: ele.state.insur_chiropractic,
    insur_naturopath: ele.state.insur_naturopath,
    insur_company: ele.state.insur_company,
    insur_policynum: ele.state.insur_policynum,
    insur_memberid: ele.state.insur_memberid,
    seriousdata: ele.state.seriousdata,
    seriousothers: ele.state.seriousothers,
    haveallergy: ele.state.haveallergy,
    whatallergy: ele.state.whatallergy,
    ongoinghealthy: ele.state.ongoinghealthy,
    longtertreat: ele.state.longtertreat,
    patientsign: patientsign,
    patientsigndate: patientsigndate,
    guardiansign: guardiansign,
    guardiansigndate: guardiansigndate,
    practitionersign: practitionersign,
    practitionersigndate: practitionersigndate,
  };

  const this2 = ele;
  var error = "";
  if (ele.state.telephone === "") error = "Please fill in telephone";
  else if (ele.state.firstname == "") error = "Please fill in firstname";
  else if (ele.state.lastname == "") error = "Please fill in lastname";
  else if (ele.state.address == "") error = "Please fill in address";
  else if (ele.state.contactname == "")
    error = "Please fill in emergency contact name";
  else if (ele.state.contacttel == "")
    error = "Please fill in emergency contact telephone";
  else if (ele.state.familydoc == "") error = "Please fill in family doctor";
  else if (ele.state.familydoctel == "")
    error = "Please fill in family doctor telephone";
  else if (ele.state.familydocaddress == "")
    error = "Please fill in family doctor address";
  else if (patientsign == "") error = "Please fill in patient signature";
  else if (practitionersign == "")
    error = "Please fill in practitioner signature";

  //document.getElementById("result2").style.color = "red";
  if (error !== ""&&needyz==1) {
    ele.setState({
      postresult: error,
    });
    return;
  }

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=savespa&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;
        var msg = res.s;

        if (r === "1") {
          //  this.postresult = "登陆成功！";

          MyToastShow(
            this2,
            2,
            "Successfully!",
            200,
            120,
            "success",
            "addemployee"
          );
          this2.setState({
            postresult: "",
          });
          //if (myusername == this2.state.username)
          //this2.props.history.push("/user");
        } else {
          MyToastShow(this2, 2, msg, 200, 120, "failse", "addemployee");
          //document.getElementById("result2").style.color = "red";
          this2.setState({
            postresult: "",
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  
}
