import React, { Component, Fragment } from "react";

import { AJAROOT, bookButtonColor } from "@/common/gdata/global.js";
//import logoPic from "../../statics/logo.png";
import "@/css/user.css";
import $ from "jquery";
class UserTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      showextend: false,
      showupgrade: false,
      extendperiod: [
        { title: "1 month", fee: 100, value: 1 },
        { title: "3 months", fee: 100, value: 3 },
        { title: "6 months", fee: 100, value: 6 },
        { title: "1 year", fee: 100, value: 12 },
      ],
      selectedextend: 1,
      selectedpaymethod:false
    };
  }
  render() {
    let {
      myusername,
      usertypename,
      myusertype,
      myusergrade,
      hba,
      hbc,
      parentusername,
      tj,
      totaljj,
      lastname,
      regtime,
      gradelist,
      gradenum,
      periodsmsbalance,
      periodbooks,
      totalsms,
      expiredate,
      usergrade,
    } = this.props;
    console.log(tj);

    var gradeprice = 1;
    //if (gradelist&&gradelist.length > 0) gradeprice = gradelist[usergrade - 1].price;
    //  if (zsxm === "") zsxm = myusername;
    // console.log(require("../../statics/logo.png"));
    return (
      <Fragment>
        <div
          className="row usertop"
          style={{ height: myusertype == 3 ? "250px" : "180px" }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 left ">
            <div
              className="row"
              style={{ lineHeight: "30px", paddingLeft: "10px" }}
            >
              <div
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Hello, {lastname}
              </div>

              <div>Account: {myusername}</div>

              <div>User Type: {usertypename}</div>
              {myusertype == 3 && (
                <div>
                  User Grade: {usergrade}
                  {usergrade < gradenum && parentusername == "" && (
                    <button
                      onClick={() => this.extend()}
                      style={{
                        color: "#fff",
                        borderWidth: "0px",
                        marginLeft: "15px",
                        backgroundColor: "#57c0e3",
                        borderRadius: "5px",
                      }}
                    >
                      Upgrade
                    </button>
                  )}
                </div>
              )}

              {myusertype == 3 && (
                <div style={{ marginTop: "8px", position: "relative" }}>
                  <div>
                    {" "}
                    Expiration: {expiredate}
                    {parentusername == "" && (
                      <button
                        onClick={() => this.extend()}
                        style={{
                          color: "#fff",
                          borderWidth: "0px",
                          marginLeft: "15px",
                          backgroundColor: "#57c0e3",
                          borderRadius: "5px",
                        }}
                      >
                        Extend
                      </button>
                    )}
                    {this.state.showextend && (
                      <div
                        style={{
                          position: "absolute",
                          background: "#f6f6f6",
                          width: "550px",
                          minHeight: "430px",
                          border: "1px solid #111",
                          left: "100px",
                          top: "15px",

                          zIndex: 9999,
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          className={"row"}
                          style={{
                            marginBottom: "15px",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter"></div>
                          <div
                            className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright"
                            style={{
                              fontWeight: "bold",
                              fontSize: "23px",
                              paddingRight: "15px",
                            }}
                            onClick={() =>
                              this.setState({
                                showextend: false,
                              })
                            }
                          >
                            X
                          </div>
                        </div>

                        <div
                          className="textcenter"
                          style={{ fontWeight: "bold", fontSize: "17px" }}
                        >
                          Select Extend Period
                        </div>

                        <div style={{ padding: "8px", paddingLeft: "50px" }}>
                          {this.state.extendperiod.map((item, index) => {
                            //  let kfh = item.kfh;
                            //let kfhname = "开户行";
                            //  if (kfh.indexOf("/image") >= 0) kfhname = "二维码";
                            return (
                              <div className="row" style={{ padding: "5px" }}>
                                <div
                                  className=""
                                  key={"extendperiod" + index}
                                  style={{
                                    padding: "5px",
                                    float: "left",
                                    fontSize: "17px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="extendperiod"
                                    value={index}
                                    onChange={() => this.selExtend(index)}
                                    checked={
                                      this.state.selectedextend == index
                                        ? true
                                        : false
                                    }
                                    style={{ width: "19px", height: "19px" }}
                                  ></input>
                                  <span
                                    style={{
                                      fontSize: "17px",
                                      paddingLeft: "9px",
                                    }}
                                  >
                                    {" " + item.title}, CAD $
                                    {gradeprice * item.value}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        {!this.state.selectedpaymethod&&<div
                          className="row textcenter"
                          style={{ paddingTop: "10px" }}
                        >
                          <button
                            className="cart"
                            style={{
                              height: "35px",
                              lineHeight: "35px",
                              width: "200px",

                              background: bookButtonColor,
                              border: "0px",
                              color: "white",
                              borderRadius: "5px",
                            }}
                             onClick={() =>
                              this.setState({
                                selectedpaymethod: true,
                              })
                            }
                            /* onClick={() => this.subPayExtend()} */
                          >
                            <span
                              className="iconfont"
                              style={{ fontSize: "18px" }}
                            >
                              &#xe63f;
                            </span>{" "}
                            <span className="c1">Pay Now</span>
                          </button>
                        </div>}


                        {this.state.selectedpaymethod&&
                        <>
                        <div>信用卡</div>
                        <div
                          className="row textcenter"
                          style={{ paddingTop: "10px" }}
                        >
                          <button
                            className="cart"
                            style={{
                              height: "35px",
                              lineHeight: "35px",
                              width: "200px",

                              background: bookButtonColor,
                              border: "0px",
                              color: "white",
                              borderRadius: "5px",
                            }}
                            
                            /* onClick={() => this.subPayExtend()} */
                          >
                            <span
                              className="iconfont"
                              style={{ fontSize: "18px" }}
                            >
                              &#xe63f;
                            </span>{" "}
                            <span className="c1">Submit</span>
                          </button>
                        </div></>
                        }
                      </div>
                    )}
                  </div>
                {/*   <div> SMS Balance: CAD ${periodsmsbalance}</div> */}
                  <div> Period Books: {periodbooks}</div>
                </div>
              )}
              {myusertype != 1 && myusertype != 3 && (
                <div>Reg Time: {regtime}</div>
              )}
              {myusertype == 1 && (
                <div>
                  <span>Last month： $ {tj["lastmonthtip"]} tips</span>
                  <span style={{ paddingLeft: "15px" }}>
                    This month： $ {tj["monthtip"]} tips
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*     userttype>=2 今日未完book 本月累计book usertype==3 
    usertype=1 今日BOOk 明日book 本月累计book 本月累计佣金
   usertype=0 今日预约 累计消费
     
    */}

        {myusertype == 3 && (
          <div
            className="row yue"
            style={{ height: "35px", lineHeight: "35px", paddingLeft: "10px" }}
          >
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              Today：
              {tj["todaybook"]} books
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              Tomorrow：
              {tj["tomorrowbook"]} books
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <span>Today new clients: {tj["todayfirst"]}/{tj["all"]} </span>
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <span>This month new clients: {tj["monthfirst"]} </span>
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  subPayExtend() {
    const that = this;
    const { myusername, ukey } = this.props;

    const encodeukey = encodeURIComponent(ukey);
    //var addid = this.state.addid;

    var cando = true;
    var error = "";
    if (this.state.fkfs === "") {
     // cando = false;
     // error = "请选择支付方式！";
    }
    if (!cando) {
      this.setState({
        error: error,
      });
    } else {
      const postdata = {
        uid: myusername,
        period: this.state.extendperiod[this.state.selectedextend].value,


      };

      var geturl =
        AJAROOT +
        "/postaja/savexx.aspx?action=payextend&u=" +
        myusername +
        "&ukey=" +
        encodeukey +
        "";

      $.ajax({
        type: "POST",
        dataType: "json",
        url: geturl,
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          var r = data.r;
          var result = data.s;

          //   return;
          if (r === "1") {
            //  this.postresult = "登陆成功！";

          
           
          } else {
            that.setState({
              error: result,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  selExtend(index) {
    this.setState({
      selectedextend: index,
    });
  }
  extend() {
    this.setState({
    //  showextend: true,
    });
    console.log("aaa");
  }
}

export default UserTop;
