import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import axios from "axios";
import $ from "jquery";
import { AJAROOT, MinDawai, offTimeColor, mainGreen, bookButtonColor, greeTitleBg } 
from "@/common/gdata/global.js";

import queryString from "query-string";
import {
  checkUserLogin,
  MyToast,
  MyToastShow,
  // showStuffTime,
  showStuffDayHours,
  getDateFromPeriodno,
  userMenuForm,
  getCustomerData,
  formatDate,
  //bookForm,
  ticketForm,
  getPeriodnoFromDate,
  NoteForm,
  // formatDate,
} from "@/jscript/common.js";

import "@/css/user.css";
import "@/css/react-datepicker.css";
//import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";
import { littleBlueColor } from "../../../common/gdata/global";
//import MenuItem from "antd/lib/menu/MenuItem";

class Index extends Component {
  constructor(props) {
    super(props);
    this.resetClicked = false;
    this.isxiala = false;
    this.timeRef = React.createRef();

    this.canvasRef1 = React.createRef();
    this.canvasRef2 = React.createRef();
    this.canvasRef3 = React.createRef();
    this.state = {
      leftClose: false,
      showcustomercheck: true,
      //  myu: "",
      title: "",
      editid: "",
      edititemid: "",
      username: "",

      name: "",
      telephone: "",
      usertel: "",
      selectedservice: "",
      serviceid: "",
      servicetext: "",
      serviceminute: 0,
      // serviceminutes: 0,
      ticketbooklist: [],
      periodno: "",
      fixedemployee: 0,

      remark: "",
      // dianusername: ",",
      // branchlist: [],
      servicelist: [],
      employeelist: [],
      employeeusername: "",
      selectedemployee: "",
      schfromdate: new Date(new Date().setDate(1)),
      schtodate: "",
      postresult: "",
      posting: false,
      checkerror: "",
      timepanel: 1,
      mytoast: {
        show: false,
      },
      fixdate: 0,
      fixtime: [], //固定日期每天时间
      avarangedate: [], //范围日期所有天
      colors: [
        { title: "Available Time", color: "#fff" },
        { title: "Booked Time", color: "yellow" },
        { title: "Off Time", color: "#ccc" },
      ],
      colors2: [
        { title: "WorkOn Time", color: "#fff" },
        { title: "Block Time", color: offTimeColor },
      ],

      months: [],

      weeks: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      worktimelist: [],
      bookdata: { nickname: "", employee: "", periodno: "", mins: [] },
      savedbookdata: { nickname: "", employee: "", periodno: "", mins: [] },
      oldemployee: "",
      oldperiodno: "",
      oldfromtime: "",
      oldtotime: "",
      selectedday: "",
      schusername: "",
      customerlist: [],
      currentmenuindex: -1,
      currentmenuindex2: -1,
      currentmenuindex3: -1,
      showbookPanel: 0,
      //showcustumerPanel: 0,
      showticketPanel: 0,

      isDragging: false,
      offsetX: 0,
      offsetY: 0,
      left: 160,
      top: 30,
      orileft: 160,
      oritop: 30,

      isDragging2: false,
      offsetX2: 0,
      offsetY2: 0,
      left2: 50,
      top2: 0,
      orileft2: 50,
      oritop2: 0,
      pubdowndraging: true,
      books: [],
      dragingbookid: 0,
      usermenu: 0,
      defaultform: "basicform",
      tickettip: 0,
      today: formatDate(new Date(), "yyyy-MM-dd"),
      showuserremark: 0,
      editremarkbookid: 0,
      newcustomerpanel: 0,
      newtelephone: "",
      newfirstname: "",
      newlastname: "",
      schservicetitle: "",
      filteredservicelist: [],
      servicelistshow: 0,
      selectedservicecateindex: 0,
      historybooks: [],
      historynote:[],
      hours: [
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
      ],
      minutes: ["00", "10", "20", "30", "40", "50"],
      rebook: 0,
      blockpanel: 0,

      sys_mingrid_height: 19,

      newbook: 1,
      defaultfromtime: "10:00",
      checkpanel: 0,
      usertype1book_periodnos:[]
    };

    /* 
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.handleMouseDown2 = this.handleMouseDown2.bind(this);
    this.handleMouseMove2 = this.handleMouseMove2.bind(this);
    this.handleMouseUp2 = this.handleMouseUp2.bind(this);
    this.handleMouseLeave2 = this.handleMouseLeave2.bind(this);  */
  }

  /*   resetPos() {
    this.setState({
    isDragging2: false,
 
      dragingbookid: 0,
      pubdowndraging: true,
      isDragging2: false,
      orileft2: 50,
      oritop2: 0,
      left2: 50,
      top2: 0,
    });
  } */
  closeLeft() {
    this.setState({
      leftClose: !this.state.leftClose,
    });
  }
  handleMouseDown(e) {
    this.setState({
      isDragging: true,
      offsetX: e.pageX,
      offsetY: e.pageY,
    });
  }

  handleMouseMove(e) {
    if (this.state.isDragging) {
      this.setState({
        left: this.state.orileft + (e.pageX - this.state.offsetX),
        top: this.state.oritop + (e.pageY - this.state.offsetY),
      });
    }
  }
  handleMouseLeave() {
    this.setState({
      orileft: this.state.left,
      oritop: this.state.top,
      isDragging: false,
    });
  }
  handleMouseUp() {
    this.setState({
      orileft: this.state.left,
      oritop: this.state.top,
      isDragging: false,
    });
  }
  /**/
  handleMouseDown2(e, bookid) {
    if (this.state.showuserremark == 1) return;
    this.setState({
      isDragging2: true,
      offsetX2: e.pageX,
      offsetY2: e.pageY,
      dragingbookid: bookid,
    });
  }

  handleMouseMove2(e, bookid) {
    if (this.state.showuserremark == 1) return;
    if (this.state.isDragging2) {
      const diff = 10;

      if (
        Math.abs(e.pageY - this.state.offsetY2) >= diff ||
        Math.abs(e.pageY - this.state.offsetY2) >= diff
      ) {
        this.setState({
          pubdowndraging: false,
          left2: this.state.orileft2 + (e.pageX - this.state.offsetX2),
          top2: this.state.oritop2 + (e.pageY - this.state.offsetY2),
        });
      }
    }
  }
  handleMouseLeave2(e, bookid) {
    if (this.state.showuserremark == 1) return;
    this.setState({
      orileft2: this.state.left2,
      oritop2: this.state.top2,
      isDragging2: false,
    });
  }
  handleMouseUp2(e, bookid) {
    if (this.state.showuserremark == 1) return;
    this.setState({
      orileft2: this.state.left2,
      oritop2: this.state.top2,
      isDragging2: false,
    });
  }
  render() {
    const { myusername,myusertype} = this.props;
    //  let menujson = Menu();
    // console.log(menujson);

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //let times = "";

    if (this.state.editid != "" && this.state.savedbookdata.mins.length == 0) {
      let _fromtime = this.state.savedbookdata.fromtime;

      //  let _totime = this.state.savedbookdata.totime;
      // if (_fromtime * 1 == 0) _fromtime = "00";
      // if (_totime * 1 == 0) _totime = "00";
      if (typeof _fromtime != "undefined")
        _fromtime = _fromtime.replace(":0", ":00");
      // if (typeof _totime != "undefined") _totime = _totime.replace(":0", ":00");
      //   times = _fromtime + " - " + _totime;
    } else if (this.state.savedbookdata.mins.length > 0) {
      //  console.log("bb");

      let _fromtime = this.state.savedbookdata.mins[0];

      //  let _totime =
      //   this.state.savedbookdata.mins[this.state.savedbookdata.mins.length - 1];

      if (typeof _fromtime != "undefined")
        _fromtime = _fromtime.replace(":0", ":00");
      // if (typeof _totime != "undefined") _totime = _totime.replace(":0", ":00");
      // times = _fromtime + " - " + _totime;
    }

    let nickname = this.state.savedbookdata.nickname;
    if (nickname != "") nickname = nickname + "，";
    return (
      <div
        className="container wrap "
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        {/*  <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form"
            style={{}}
          > */}
        <div className={"row "} style={{ position: "relative" }}>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form"
            style={{
              position: "fixed",
              background: "#fff",
              height: "100%",
              width: "100%",
              border: "1px solid #ddd",
              top: 0,
              left: 0,

              zIndex: 1002,
              padding: "0px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          /*  onClick={() => {
                    this.HiddenMenu();
                  }} */
          >
            <div className="row textright " style={{ margin: 0, padding: 0 }}>
              {/* 左侧开始 */}
              <div
                style={{
                  borderRight: "1px solid #aaa",
                  float: "left",
                  width: myusertype=="1"?"50%":"15%",
                  paddingLeft: "5px",

                  background: "#f6f6f6",
                  paddingTop: "10px",
                  display: this.state.leftClose ? "none" : "block",
                  height:"calc(100vh - 5px)",
                  overflowY: "auto",
                }}
              >

                <div className="textleft">
                  {/*   <div onClick={() => this.hiddenLeft()}  style={{
                      textAlign: "center",
                    
                    }}>&lt;&lt;</div> */}
                  <div
                    className=""
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      margin: "3px",
                      padding: "10px",
                      width: "98%",
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                      }}
                    >
                      <div
                        style={{
                          float: "left",
                          padding: "6px 0px"
                        }}
                      >
                        <div
                          style={{
                            float: "left",
                            width: "45%",
                          }}
                        >
                          <DatePicker
                            selected={this.state.schfromdate}
                            onChange={(ref) => this.setStartDate(ref)}
                            showYearDropdown
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Input from date"
                            className="datePicker_panel"

                          />
                        </div>
                        <div
                          style={{
                            float: "left",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          -
                        </div>
                        <div
                          style={{
                            float: "left",
                            width: "45%",
                          }}
                        >
                          <DatePicker
                            selected={this.state.schtodate}
                            onChange={(ref) => this.setToDate(ref)}
                            showYearDropdown
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Input end date"
                            className="datePicker_panel"

                          />
                        </div>
                      </div>
                      {myusertype*1>=2&& <div
                        style={{
                          textAlign: "left",
                          float: "left", width: "100%",
                          padding: "6px 0px"
                        }}
                      >

                        <span
                          onClick={() => this.setrange("3month")}
                          style={{
                            marginRight: "13px",
                            borderBottom: "1px solid #000",
                            cursor: "pointer",
                          }}
                        >
                          3 Months
                        </span>
                        <span
                          onClick={() => this.setrange("6month")}
                          style={{
                            marginRight: "13px",
                            borderBottom: "1px solid #000",
                            cursor: "pointer",
                          }}
                        >
                          6 Month
                        </span>
                        <span
                          onClick={() => this.setrange("1year")}
                          style={{
                            borderBottom: "1px solid #000",
                            cursor: "pointer",
                          }}
                        >
                          1 Year
                        </span>

                      </div>}
                    </div>

                    {myusertype*1>=2&&<div
                      style={{
                        float: "left", width: "90%",
                        padding: "6px 0px"
                      }}
                    >
                      <select
                        style={{ width: "90%", margin: "5px" }}
                        name="employee"
                        className="schfield"
                        value={this.state.selectedemployee}
                        onChange={(e) => this.setEmployeeUsername(e)}
                      /*  onChange={(ref) => this.setUsername(ref)} */
                      >
                        <option value="">All Staff</option>
                        {this.state.employeelist.map((item, index) => {
                          //  const date2 = new Date(arr[0], arr[1], item2);

                          return (
                            <option key={"emply" + index} value={item.username}>
                              {item.firstname} {item.lastname}
                            </option>
                          );
                        })}
                      </select>
                    </div>}
                    <div
                      style={{
                        float: "center",
                        textAlign: "left",
                        paddingLeft: "3px",

                        width: "100%",

                      }}
                    >
                      <input
                        type="button"
                        name="schbut"
                        value=" Search Date "
                        style={{ width: "90%", background: mainGreen,color:"white" }}
                        className="add"
                        onClick={() => this.searchDate()}
                      ></input>
                    </div>
                  </div>
                  <div style={{ clear: "both" }}>
                    <div
                      style={{
                        color: "",
                        paddingLeft: "10px",
                      }}
                      className="postresult"
                    >
                      {this.state.checkerror}
                    </div>
                  </div>
                </div>


                {myusertype*1>=2&&<div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    margin: "5px",
                    paddingLeft: "10px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <input
                    onClick={() => this.checkDay(this.state.today, 0)}
                    type="button"
                    name=""
                    value=" Today "
                    className="add"
                    style={{
                      fontSize: "13px",
                      color: "#fff",
                      backgroundColor: "#333",
                      borderRadius: "5px",
                      width: "40%",
                      marginRight: "10px",
                      height: "30px",
                      lineHeight: "30px",
                    }}
                  ></input>
                  <input
                    onClick={() =>
                      this.checkDay(
                        formatDate(
                          new Date(Date.now() + 24 * 60 * 60 * 1000),
                          "yyyy-MM-dd"
                        ),
                        0
                      )
                    }
                    type="button"
                    name=""
                    value=" Tomorrow "
                    className="add"
                    style={{
                      fontSize: "13px",
                      color: "#fff",
                      backgroundColor: "#333",
                      borderRadius: "5px",
                      width: "40%",
                      height: "30px",
                      lineHeight: "30px",
                    }}
                  ></input>
                </div>}
                <div className="" style={{ whiteSpace: "nowrap" }}>
                  {this.state.months.map((item, index) => {
                    let arr = item.v.split("-");

                    // let yearno = arr[0] * 1;
                    //  let monthno = arr[1] * 1;

                    const date1 = new Date(arr[0], arr[1] - 1, 1);

                    let blankdays = [];
                    let _index = date1.getDay() - 1;
                    if (_index == -1) _index = 6;
                    for (let j = 0; j < _index; j++) {
                      blankdays.push(j);
                    }

                    return (
                      <div
                        style={{
                          float: "left",
                          width: "98%",
                          height: "250px",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          margin: "3px",
                          padding: "0px",
                        }}
                        key={"group" + index}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            padding: "2px",
                            fontWeight: "bold",
                            height: "30px",
                            lineHeight: "30px",
                            background: mainGreen,
                            color:"white"
                          }}
                        >
                          {this.state.employeeusername} {item.v}
                        </div>
                        <div
                          style={{
                            height: "195px",
                            position: "relative",
                            marginTop: "5px", marginLeft: "5px"
                          }}
                        >
                          {this.state.weeks.map((item2, index2) => {
                            //  const date2 = new Date(arr[0], arr[1], item2);
                            let bgcolor = "#fff";
                            if (item.weeks[index2].check == 1)
                              bgcolor = "#0066ff";

                            return (
                              <div
                                style={{
                                  backgroundColor: bgcolor,
                                  border: "1px solid #ddd",
                                }}
                                className="calendarday"
                                key={"weeks" + index2}
                              >
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "11px"
                                  }}
                                /*  onClick={() =>
                                        this.selectWeek(index, index2)
                                      } */
                                >
                                  {(item2)}
                                </div>
                              </div>
                            );
                          })}

                          {blankdays.map((item2, index2) => {
                            return (
                              <div
                                className="calendarday"
                                key={"blank" + index2}
                                style={{ border: "0px" }}
                              ></div>
                            );
                          })}

                          {item.days.map((item2, index2) => {
                            //  const date2 = new Date(arr[0], arr[1], item2);
                            // let fulltime = -2;
                            let day = item2.v;
                            if (day <= 9) day = "0" + day;

                            // var periodno = item.v.replace("-", "") + "" + day;

                            let bgcolor = "#fff"; //this.state.colors2[1].color;

                            /*  let _item = null;
                                if (item2.check == 1) bgcolor = "#0066ff";
                                else {
                                  _item = this.state.avarangedate.find(
                                    (item3) => item3.periodno === periodno
                                  );
                                  //console.log("not null" + _item);
                                  if (_item != null) {
                                    bgcolor = this.state.colors2[0].color;
                                  }
                                } */
                            let fontcolor = "#000";
                            let v = item2.v;
                            if (v <= 9) v = "0" + v;
                            let current = item.v + "-" + v;
                            if (this.state.selectedday == current) {
                              bgcolor = bookButtonColor;
                              fontcolor = "#fff";
                            }
                            let day2 = this.state.months[index].days[index2].v;
                            if (day2 <= 9) day2 = "0" + day2;
                            let _day = this.state.months[index].v + "-" + day2;
                          
                          
                           if(myusertype*1==1)
                           {
                            let _periodno=_day.replace(/-/g,"");
                            if(this.state.usertype1book_periodnos.includes(_periodno))
                            {
                              bgcolor=littleBlueColor
                            }
                          }
                            return (
                              <div key={"day" + index2}>
                                <div
                                  className="calendarday"
                                  style={{
                                    backgroundColor: bgcolor,
                                    border: "1px solid #ddd",
                                    height: "30px",
                                    lineHeight: "30px",
                                    padding: "0px",
                                  }}
                                >
                                  <div
                                    onClick={() => this.checkDay(_day, 0)}
                                    style={{
                                      textAlign: "center",
                                      color: fontcolor,
                                    }}
                                  >
                                    {item2.v}
                                  </div>
                                </div>
                                <div
                                  id={"day" + index + ""}
                                  style={{
                                    display: "none",
                                    position: "absolute",
                                    background: "#f6f6f6",
                                    width: "300px",
                                    minheight: "300px",
                                    border: "1px solid #ddd",
                                    top: 200,
                                    left: -0,
                                    zIndex: 9,
                                    padding: "10px",
                                  }}
                                ></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {myusertype*1>=2&&<div
                  className=""
                  style={{
                    clear: "both",
                    margin: "10px",
                    padding: "10px",
                    /*  background: "#f6f6f6", */
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ textAlign: "left" }}>Tips:</div>
                  <div style={{ textAlign: "left" }}>
                    1、Double-click the time slot to add a new appointment.
                  </div>
                  <div style={{ textAlign: "left" }}>
                    2、Double-click the already book block to edit.
                  </div>
                  <div style={{ textAlign: "left" }}>
                    3、Right-click the time slot to block or set on of the
                    employee's worktime.
                  </div>
                  <div style={{ textAlign: "left" }}>
                    4、When scrolling is necessary for a window, using the left
                    and right arrow keys on the keyboard can directly scroll the
                    window.
                  </div>
                </div>}
              </div>

              {/* 左侧结束 */}

              <div style={{ float: "left", width: !this.state.leftClose ?myusertype=="1"?"50%":"85%" : "100%" }}>
                <div
                  style={{
                    clear: "both",
                    justifyItems: "center",
                    alignItems: "center",

                    display: "flex",
                    justifyContent: 'space-between',

                  }}
                >
                   {myusertype*1>=2&&<div
                    style={{ paddingLeft: "19px", float: "left" }}
                    onClick={() => this.closeLeft()}
                  >
                    {!this.state.leftClose && <span className="iconfont fanhui">&#xec7c;</span>}
                    {this.state.leftClose && <span className="iconfont fanhui">&#xe60b;</span>}
                  </div>}
                  <div style={{ float: "left", padding: "5px", flexGrow: "1" }}>

                    <div
                      style={{
                        textAlign: "left",

                        float: "left",
                        marginLeft: "30px",
                        marginRight: "130px",
                      }}
                    >
                      {this.state.selectedday}
                    </div>
                    <div
                      style={{
                        float: "left",

                        marginLeft: "10px",
                        display: "flex",
                      }}
                    >
                      {this.state.colors2.map((item, index) => {
                        return (
                          <div
                            key={"color" + index}
                            style={{ float: "left", display: "flex" }}
                          >
                            <div
                              style={{
                                float: "left",
                                verticalAlign: "middle",

                                marginRight: "2px",
                              }}
                            >
                              {" "}
                              {item.title}
                            </div>
                            <div
                              style={{
                                float: "left",
                                backgroundColor: item.color,
                                width: "50px",
                                height: "25px",

                                marginRight: "10px",
                                border: "1px solid #bbb",
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>


                  </div>
                  <div
                    style={{
                      paddingLeft: "50px",
                      textAlign: "right",

                      verticalAlign: "middle",
                      display: "flex",

                      marginRight: "25px",
                      fontSize: "22px",

                      width: "auto"
                    }}
                    onClick={() => window.history.back(-1)}
                  >
                    X
                  </div>
                </div>
                <div style={{ clear: "both" }}> </div>

                <div style={{
  overflowX: "auto",
                }}>
                <div
      
          style={{
          
            width: "auto",
            height: "30px",
            lineHeight: "30px",
            border: "0px solid #111",
         
            padding: "0px",
            borderRadius: "px",
    
            whiteSpace: "nowrap",
            overflowX: "hidden",
            overflowY:"hidden",
            display: "inline-block",
            float:"left",minWidth:"100%",
            textAlign:"left"
          }}
        >
          {this.state.fixtime.map((item, index) => {

            if (item.hours != null) {

              return (
                <div
                  key={"1fixuer" + index}
                  style={{
                    background: "#f6f6f6",
                    padding: "0px",
                    border: "0px solid #ddd",
                    width: "175px",

                    display: "inline-block",
                    margin: "0px", fontWeight: "bold",
            
                  }}
                >
                  <div className="textcenter">{item.zsxm}</div>

                </div>
              );
            }
          })}
        </div>



                {this.state.fixtime.length > 0 && (

                  
                  <div
                    id="scrollable"
                    style={{
                      overflowX: "hidden",
                      width: "auto",
                      whiteSpace: "nowrap",
                      display: "flex",
                      transform: "rotateX(180deg)",
                      display: "inline-block",
                      float:"left"
                      ,minWidth:"100%",
                      background:myusertype*1>=2?mainGreen:"#fff",
                     
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        transform: "rotateX(180deg)",
                        display: "flex",
                        overflowX: "hidden",
                        overflowY: "auto",
                        height:"calc(100vh - 8rem)",
                        display: "inline-block",
                          float:"left",
                          
                      }}
                    >
                      {this.state.fixtime.map((item, index) => {
                        //  const date2 = new Date(arr[0], arr[1], item2);

                        let hours = item.hours;
                        // if (hours == null) hours = [];
                        //hours = JSON.parse(hours);
                        if (item.hours != null) {
                          // hours = JSON.parse(hours);
                          var periodno = item.periodno;
                          periodno =
                            periodno.substring(0, 4) +
                            "-" +
                            periodno.substring(4, 6) +
                            "-" +
                            periodno.substring(6);

                          return (
                            <div
                              key={"fixuer" + index}
                              style={{
                                padding: "0px",
                                border: "0px solid #ddd",
                                width: "175px",
                                display: "inline-block",
                                margin: "0px",
                                verticalAlign:"top",
                                
                              }}
                            >
                           {/*    <div className="textcenter" style={{ background: "#f6f6f6", height: "25px", lineHeight: "25px", fontWeight: "bold" }}>{item.zsxm}</div>
                            */}   <div>
                             
                                {showStuffDayHours(
                                  this,
                                  item.username,
                                  hours,
                                  index,
                                  myusertype*1==1?0:item.blankrow
                                )}
                              </div>
                              <div className="textcenter" style={{ background: "#f6f6f6", height: "25px", lineHeight: "25px" }}>{item.zsxm}</div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            </div>
          </div>
        </div>
        {this.state.showticketPanel == 1 && (
          <div
            id="TicketFormDiv"
            style={{
              position: "fixed",
              background: "#f6f6f6",
              width: "950px",
              minHeight: "595px",
              border: "1px solid #111",
              left: this.state.left,
              top: this.state.top,

              zIndex: 9999,
              padding: "10px",
              borderRadius: "5px",
              cursor: this.state.isDragging ? "grabbing" : "grab",
              overflowY: "auto",
            }}
          /*  onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
          onMouseLeave={this.handleMouseLeave} */
          >

            {ticketForm(this)}
          </div>
        )}
{/* 
{
        <div
          id="EmployeeListDiv"
          style={{
            position: "fixed",

            width: "auto",
            height: "30px",
            lineHeight: "30px",
            border: "0px solid #111",
            left: "13%",
            top: "3.5rem",
           


            zIndex: 9999,
            padding: "0px",
            borderRadius: "px",
            display: "block",
            whiteSpace: "nowrap",
            overflowX: "auto"
          }}
        >
          {this.state.fixtime.map((item, index) => {

            if (item.hours != null) {

              return (
                <div
                  key={"1fixuer" + index}
                  style={{
                    background: "#f6f6f6",
                    padding: "0px",
                    border: "0px solid #ddd",
                    width: "175px",

                    display: "inline-block",
                    margin: "3px", fontWeight: "bold"
                  }}
                >
                  <div className="textcenter">{item.zsxm}</div>

                </div>
              );
            }
          })}
        </div>
        } */}

        {this.state.usermenu == 1 && (
          <div
            style={{
              position: "fixed",
              background: "#eee",
              width: "1200px",
              height: "600px",
              border: "1px solid #111",
              left: this.state.left,
              top: 50,

              zIndex: 99999,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {userMenuForm(this, this.state.username)}
          </div>
        )}

        {this.state.showuserremark == 1 && (
          <div
            style={{
              position: "fixed",
              width: "300px",
              height: "300px",
              border: "1px solid #ddd",
              backgroundColor: "#f6f6f6",
              //borderTop: "2px solid #ddd",
              // borderBottom: "2px solid #ddd",
              top: "50%",
              left: "50%",
              zIndex: 999999,
              margin: "5px",
              borderRadius: "8px",
              padding: "5px",
              transform: "translate(-50%, -50%)"
            }}
          /*  onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
          onMouseLeave={this.handleMouseLeave} */
          >
            {NoteForm(this)}
          </div>
        )}

        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }

  ShowNewCustomer(val) {
    this.setState({
      newtelephone:this.state.schusername,
      newcustomerpanel: val,
      postresult: "",
    });
  }

  SaveNewCustomer() {
    const { myusername, ukey } = this.props;
    let error = "";
    if (this.state.newtelephone == "") {
      error = "Please fill in telephone";
    } else if (this.state.firstname == "") {
      error = "Please fill in first name";
    }
    if (error != "") {
      this.setState({
        postresult: error,
      });
      return;
    }
    const postdata = {
      uid: myusername,

      telephone: this.state.newtelephone,
      firstname: this.state.newfirstname,
      lastname: this.state.newlastname,
    };


    let name = this.state.newfirstname;
    if (this.state.newlastname != "") {
      name = name + " " + this.state.newlastname;
    }
    const that = this;

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=newcustomer&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === 1) {
          that.setState({
            username: res.username,
            usertel: that.state.newtelephone,
            name: name,
            newcustomerpanel: 0,
            postresult: "",
            newtelephone: "",
            newfirstname: "",
            newlastname: ""
          });
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
        } else {
          that.setState({
            postresult: res.s,
          });
          // MyToastShow(that, 2, res.s, 150, 160, "failse", "addbranch");
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        that.setState({
          postresult: "Network Error, Please try again",
        });
        console.log("error");
      });
  }
  ShowRemark(bookid, val) {

    this.setState({
      showuserremark: val,
      editremarkbookid: bookid,
    });
  }
  saveRemark(bookid) {
    const { myusername, ukey } = this.props;

    const postdata = {
      uid: myusername,
      username: this.state.books[bookid].username,
      col: "remark",
      val: this.state.books[bookid].userremark,
    };

    const that = this;

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=saveuserfiled&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;
        // console.log(res);
        if (r === 1) {
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            showuserremark: 0,
          });

          that.ShowNewCustomer(0);
        } else {
          MyToastShow(that, 2, res.s, 150, 160, "failse", "addbranch");
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        console.log("error");
      });
  }

  ShowUser(username, show) {//点击 Customer Info出现会员菜单
    getCustomerData(this, username);
    this.setState({
      usermenu: show,
      defaultform: "basicform",
    });
  }
  checkBook(bookid, col, val) {
    const { myusername, ukey } = this.props;
    let error = "";


    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=updatebook&id=" +
      bookid +
      "&subaction=" + col + "&val=" +
      val +
      "";


    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        this.checkDay(
          getDateFromPeriodno(this.state.books[bookid].periodno),
          1
        );

        MyToastShow(
          this,
          3,
          "Successfully! ",
          150,
          80,
          "success",
          "delemployee"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delemployee");
      }
      // this.setState({}); /**/
    });
  }

  checkBook_old(bookid, subaction, val) {
    const { myusername, ukey } = this.props;
    let error = "";

    if (subaction == "checked") {
      val = this.state.books[bookid].newchecked
        ? this.state.books[bookid].newchecked
        : "";
      if (val == "") {
        error = "Please select confirm result.";
      }
    }
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "delemployee");
      return;
    }
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=updatebook&id=" +
      bookid +
      "&subaction=" +
      subaction +
      "&val=" +
      val +
      "";


    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        this.checkDay(
          getDateFromPeriodno(this.state.books[bookid].periodno),
          1
        );
        if (subaction == "checked") {
          this.setState({
            checkpanel: 0,
            editremarkbookid: "",
          });
        }
        MyToastShow(
          this,
          3,
          "Successfully! ",
          150,
          80,
          "success",
          "delemployee"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delemployee");
      }
      // this.setState({}); /**/
    });
  }
  gotoBook(periodno, bookid) {


    this.setState({
      usermenu: 0,
    });
    this.checkDay(periodno, 2);
  }
  delTicketBook(editid, bookitemid) {

    if (!window.confirm("Are you sure to delete it?")) return;
    const { myusername, ukey } = this.props;
    let periodno = this.state.books[this.state.editid].periodno;
    //console.log(this.state.fixtime[index1]);
    // let periodno = this.state.fixtime[index1].periodno;
    // let employee = this.state.fixtime[index1].username;
    // let hour = this.state.fixtime[index1].hours[index2].v;
    // let minute = this.state.fixtime[index1].hours[index2].min[index3].v;
    // let fromtime = hour + ":" + minute;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=delbook&id=" +
      bookitemid +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        let showticketPanel = 1;
        if (editid == bookitemid) showticketPanel = 0;
        if (periodno != "")
          this.checkDay(getDateFromPeriodno(periodno), 1, showticketPanel, 0);

        MyToastShow(
          this,
          3,
          "Successfully! ",
          150,
          80,
          "success",
          "delemployee"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delemployee");
      }
      // this.setState({}); /**/
    });
  }

  editTicketBook(editid, bookitemid, index) {
    /*   if(this.state.showbookPanel==1&&this.state.newbook==0)
  {
    this.setState({
      serviceid: "",
      serviceminute: 0,
      servicetext: "",
     
      edititemid: "",
      newbook: 1,
      showbookPanel: 0,
     // bookdata: bookdata,
    });
  return;
  } */

    let bookdata = this.state.bookdata;

    let booklist = JSON.parse(this.state.books[this.state.editid].booklist);
    bookdata.employee = booklist[index].employee;
    bookdata.periodno = booklist[index].periodno;

    bookdata.fromtime = booklist[index].fromtime;
    let fixedemployee = booklist[index].fixedemployee;
    let servicetext = booklist[index].servicetitle;
    let serviceminute = booklist[index].minutes;
    let serviceid = booklist[index].serviceid;

    this.setState({
      serviceid: serviceid,
      serviceminute: serviceminute,
      servicetext: servicetext,
      fixedemployee: fixedemployee,
      edititemid: bookitemid,
      newbook: 0,
      showbookPanel: 1,
      bookdata: bookdata,
    });
  }
  delbook(bookid, index, periodno) {
    if (!window.confirm("Are you sure to delete it?")) return;
    const { myusername, ukey } = this.props;
    //console.log(this.state.fixtime[index1]);
    // let periodno = this.state.fixtime[index1].periodno;
    // let employee = this.state.fixtime[index1].username;
    // let hour = this.state.fixtime[index1].hours[index2].v;
    // let minute = this.state.fixtime[index1].hours[index2].min[index3].v;
    // let fromtime = hour + ":" + minute;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=delbook&id=" +
      bookid +
      "";

    //return;
    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        if (periodno != "") this.checkDay(getDateFromPeriodno(periodno), 1);
        else {
          periodno = this.state.bookdata.periodno;

          this.checkDay(getDateFromPeriodno(periodno), 1);
          let historybooks = this.state.historybooks;
          historybooks.splice(index, 1);
          this.setState({
            historybooks: historybooks,
          });
    
        }
        MyToastShow(
          this,
          3,
          "Successfully! ",
          150,
          80,
          "success",
          "delemployee"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delemployee");
      }
      // this.setState({}); /**/
    });
  }
  getWorkTimeToHour(index1, index2, index3) {
    let fixtime = this.state.fixtime;
    let hour = fixtime[index1].hours[index2].min[index3].tohour
      ? fixtime[index1].hours[index2].min[index3].tohour
      : fixtime[index1].hours[fixtime[index1].hours.length - 1].v * 1 + 1;
    if (hour < 10) hour = "0" + hour * 1;
    return hour;
  }

  blockTime(e, index1, index2, index3, val, bookid) {
if(this.props.myusertype*1==1)return;
    e.preventDefault(); // 阻止默认右键菜单弹出
    if (bookid != "") return;
    if (this.state.blockpanel == 1 && val == 1) return;

    let fixtime = this.state.fixtime;
    fixtime[index1].hours[index2].min[index3].blockpanel = val;
    this.setState({
      fixtime: fixtime,
      blockpanel: val,
    });
  }
  blockAllday(index1, index2, index3) {
    let fixtime = this.state.fixtime;
    fixtime[index1].hours[index2].min[index3].fromhour =
      fixtime[index1].hours[0].v;

    let fromminute = fixtime[index1].hours[0].min[0].v;
    if (fromminute == 0) fromminute = "00";
    fixtime[index1].hours[index2].min[index3].fromminute = fromminute;

    fixtime[index1].hours[index2].min[index3].tohour =
      fixtime[index1].hours[fixtime[index1].hours.length - 1].v * 1 + 1;
    fixtime[index1].hours[index2].min[index3].tominute = "00";
    /*   fixtime[index1].hours[fixtime[index1].hours.length - 1].min[
        fixtime[index1].hours[fixtime[index1].hours.length - 1].min.length - 1
      ].v;
 */
    this.setState({
      fixtime: fixtime,
    });
  }
  changeBlockfield(e, index1, index2, index3, field) {
    let val = e.target.value;

    let fixtime = this.state.fixtime;
    fixtime[index1].hours[index2].min[index3][field] = val;
    this.setState({
      fixtime: fixtime,
    });

  }
  getWorkOnValue(index1, index2, index3, v) {
    let fixtime = this.state.fixtime;
    let workon = 0;

    if (fixtime[index1].hours[index2].min[index3].workon) {
      workon = fixtime[index1].hours[index2].min[index3].workon;
    }
    if (workon == v) return true;
    else return false;
  }
  setWorkOnRadio(e, index1, index2, index3, v) {

    let fixtime = this.state.fixtime;
    fixtime[index1].hours[index2].min[index3].workon = v;
    //fixtime[index1].hours[index2].min[index3][field] = val;
    this.setState({
      fixtime: fixtime,
    });

  }
  saveBlock(username, periodno, index1, index2, index3) {
    const { myusername, ukey } = this.props;
    let fixtime = this.state.fixtime;

    let fromhour = fixtime[index1].hours[index2].min[index3].fromhour
      ? fixtime[index1].hours[index2].min[index3].fromhour
      : fixtime[index1].hours[index2].v;
    let fromminute = fixtime[index1].hours[index2].min[index3].fromminute
      ? fixtime[index1].hours[index2].min[index3].fromminute
      : fixtime[index1].hours[index2].min[index3].v;

    /*    let tohour = fixtime[index1].hours[index2].min[index3].tohour
      ? fixtime[index1].hours[index2].min[index3].tohour
      : fixtime[index1].hours[index2].v; */

    let tohour = fixtime[index1].hours[index2].min[index3].tohour
      ? fixtime[index1].hours[index2].min[index3].tohour
      : fixtime[index1].hours[fixtime[index1].hours.length - 1].v * 1 + 1;

    if (tohour < 10) tohour = "0" + tohour * 1;

    let tominute = fixtime[index1].hours[index2].min[index3].tominute
      ? fixtime[index1].hours[index2].min[index3].tominute
      : "00";

    let workon = fixtime[index1].hours[index2].min[index3].workon
      ? fixtime[index1].hours[index2].min[index3].workon
      : 0;

    let fromtime = fromhour + ":" + fromminute;
    let totime = tohour + ":" + tominute;

    let error = "";
    if (fromhour > tohour || (fromhour == tohour && fromtime > tominute))
      error = "The end time must larger than from time.";
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      return;
    }

    const postdata = {
      uid: myusername,

      employee: username,
      periodno: periodno,
      fromtime: fromtime,
      totime: totime,
      workon: workon,
    };



    const that = this;

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=editworktime&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === 1) {
          that.checkDay(getDateFromPeriodno(periodno), 1);
          //  console.log(that.state.selectedday);
          ///  console.log(getDateFromPeriodno(that.state.bookdata.periodno));
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            postresult: "",
            blockpanel: 0,
          });

          //that.props.history.push("/user/book/list");
        } else {
          //  MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: "",
            blockpanel: 0,
          });
          MyToastShow(that, 2, res.s, 150, 160, "failse", "addbranch");
          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        console.log("error");
      });
  }
  checkTimeAva(movedbookid, index, index2, index3, fromtime, minutes) {
    var error = "";

    let num = Math.ceil(minutes / MinDawai);
    let arr = fromtime.split(":");
    let firsttime = new Date(2022, 1, 1, arr[0], arr[1]);

    /*  let lasttime = new Date(2022, 1, 1, arr[0], arr[1]);
    lasttime.setTime(
      lasttime.getTime() + this.state.serviceminutes * 60 * 1000
    );
 */
    // console.log(lasttime);

    for (var j = 0; j < num; j++) {
      if (j > 0) firsttime.setTime(firsttime.getTime() + MinDawai * 60 * 1000);

      //let _time = firsttime.getHours() + ":" + firsttime.getMinutes();

      let _item = this.state.fixtime[index].hours.find(
        (item3) => item3.v === firsttime.getHours()
      );

      if (_item != null) {
        let _item2 = _item.min.find(
          (tmpitem) => tmpitem.v === firsttime.getMinutes() && tmpitem.u === 1
        );
        if (_item2 != null && _item2.b != movedbookid) {
          error =
            "Time of " +
            firsttime.getHours() +
            ":" +
            firsttime.getMinutes() +
            " is occupied";

          break;
          //bgcolor = this.state.colors2[0].color;
        }
        //bgcolor = this.state.colors2[0].color;
      }
      //  console.log(firsttime);
      // console.log(_time);
      // if (j == num - 1 && lasttime != firsttime) {
      //  _time = firsttime.getHours() + ":" + lasttime.getMinutes();
      //  }
    }

    return error;
  }

  savepos(movieddivid, bookid, index1, index2, index3) {
    //index1 员工 index2 小时 index3分
    const { myusername, ukey } = this.props;
    const moveddiv = document.getElementById(movieddivid);
    //console.log()
    let changedindex = false;
    //console.log(moveddiv.offsetHeight);
    let minutes =
      Math.ceil(moveddiv.offsetHeight / this.state.sys_mingrid_height) *
      MinDawai;
    let oldminutes = this.state.books[bookid].minutes;

    if (minutes < 10) {
      MyToastShow(
        this,
        2,
        "Min time is 10 minutes",
        200,
        120,
        "fail",
        "addemployee"
      );
      this.resetPos(movieddivid, index1, index2, index3);
      return;
    }
    //const div2 = document.getElementById("div2");


    /* console.log(left1);
console.log(top1);

  let aaa =
              "Min_jack_20230416_" +
              index1 +
              "_" +
              index2 +
              "_" +
              index3;

            const div1 = document.getElementById(aaa);
 const _react = div1.getBoundingClientRect();

console.log(_react.left);
console.log(_react.top);
return; */
    /* let newindex1 = 0;
    let newindex2 = 0;
    let newindex3 = 0;
    let founded = false;
    const rect1 = moveddiv.getBoundingClientRect();
    const left1 = rect1.left;
    const top1 = rect1.top;
    outerLoop: for (var j = 0; j < this.state.fixtime.length; j++) {
      let username = this.state.fixtime[j].username;
      let periodno = this.state.fixtime[j].periodno;

      for (var j2 = 0; j2 < this.state.fixtime[j].hours.length; j2++) {
        for (
          var j3 = 0;
          j3 < this.state.fixtime[j].hours[j2].min.length;
          j3++
        ) {
          if (!(j == index1 && j2 == index2 && j3 == index3)) {
            let _divid =
              "Min_" +
              username +
              "_" +
              periodno +
              "_" +
              j +
              "_" +
              j2 +
              "_" +
              j3;

            const moveddiv2 = document.getElementById(_divid);
            //const div2 = document.getElementById("div2");

            const rect2 = moveddiv2.getBoundingClientRect();

            const left2 = rect2.left;
            const top2 = rect2.top;

            const right2 = rect2.right;
            const bottom2 = rect2.bottom;

            const isOverlapping =
              left1 >= left2 &&
              left1 <= right2 &&
              top1 >= top2 &&
              top1 <= bottom2;
            if (isOverlapping) {

              founded = true;
              newindex1 = j;
              newindex2 = j2;
              newindex3 = j3;
              break outerLoop;
            }
          }
        }
      }
    } */
    let found_arr = this.getNewPos(moveddiv, index1, index2, index3);
    let last_newdivid = "";
    if (found_arr) {
      index1 = found_arr[0];
      index2 = found_arr[1];
      index3 = found_arr[2];
      last_newdivid = found_arr[3];
      if (last_newdivid != "") document.getElementById(last_newdivid).style.backgroundColor = "#fff";
      changedindex = true;
    } else {
      if (oldminutes == minutes) {
        MyToastShow(this, 2, "No any changed", 200, 120, "fail", "addemployee");
        this.resetPos(movieddivid, index1, index2, index3);
        return;
      }
    }
    var employee = this.state.fixtime[index1].username;
    var periodno = this.state.fixtime[index1].periodno;

    var m = this.state.fixtime[index1].hours[index2].min[index3].v;
    if (m == 0) m = "00";
    var fromtime = this.state.fixtime[index1].hours[index2].v + ":" + m;

    var error = this.checkTimeAva(
      bookid,
      index1,
      index2,
      index3,
      fromtime,
      minutes
    );
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      that.resetPos(movieddivid, index1, index2, index3);
      return;
    }
    const postdata = {
      uid: myusername,
      bookid: bookid * 1,

      employee: employee,

      periodno: periodno,
      fromtime: fromtime,
      minutes: minutes,
    };


    const that = this;

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=movebook&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === 1) {
          //  that.resetPos(movieddivid);
          if (!changedindex) that.resetPos(movieddivid, index1, index2, index3);
          that.checkDay(getDateFromPeriodno(periodno), 1);

          //  console.log(that.state.selectedday);
          ///  console.log(getDateFromPeriodno(that.state.bookdata.periodno));
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            postresult: "",

            isDragging2: false,

            dragingbookid: 0,
          });

          //that.props.history.push("/user/book/list");
        } else {
          //  MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: "",
          });
          MyToastShow(that, 2, res.s, 150, 160, "failse", "addbranch");
          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        console.log("error");
      });
  }

  ReBook(index1, index2, index3, bookid, val, timecheck,servicetitle) {
    
    this.setState({
      editid: bookid,
      rebook: val,
      showbookPanel: 1,
    },(
      this.ShowTicket(index1, index2, index3, bookid, timecheck,servicetitle)
    ));
  }

  MoveBook(bookid, minutes) {
    let str = bookid + "|" + minutes;
    copy(str);
    MyToastShow(
      this,
      2,
      "Copied, Please click new time area, and Ctrl+V",
      200,
      120,
      "success",
      "addemployee"
    );
  }

  ParseBook(ref, index1, index2, index3) {
    const { myusername, ukey } = this.props;
    //let str = index1 + "|" + index2;
    const text = ref.clipboardData.getData("text");
    const arr = text.split("|");
    const bookid = arr[0] * 1;
    const minutes = arr[1] * 1;
    //console.log(arr);
    //return;

    var employee = this.state.fixtime[index1].username;
    var periodno = this.state.fixtime[index1].periodno;

    var m = this.state.fixtime[index1].hours[index2].min[index3].v;
    if (m == 0) m = "00";
    var fromtime = this.state.fixtime[index1].hours[index2].v + ":" + m;

    var error = this.checkTimeAva(
      bookid,
      index1,
      index2,
      index3,
      fromtime,
      minutes
    );
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      return;
    }
    const postdata = {
      uid: myusername,
      bookid: bookid,

      employee: employee,

      periodno: periodno,
      fromtime: fromtime,
      minutes: minutes
    };

    // console.log(postdata);
    // return;
    const that = this;
    //if (this.state.bookdata.nickname == "") error = "Please select time";
    //else if (this.state.telephone === "") error = "Please fill in telephone";

    // else if (this.state.password == "") error = "Please fill in password";
    // else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=movebook&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === 1) {
          that.checkDay(getDateFromPeriodno(periodno), 1);
          //  console.log(that.state.selectedday);
          ///  console.log(getDateFromPeriodno(that.state.bookdata.periodno));
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            postresult: "",
          });
          //that.props.history.push("/user/book/list");
        } else {
          //  MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: "",
          });
          MyToastShow(that, 2, res.s, 150, 160, "failse", "addbranch");
          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) { });

    //console.log(this.state.fixtime[index1]);
    //setPasteContent(text);

    // console.log(text);
  }

  /*  addBook(val) {
    this.setState({ showbookPanel: val, rebook: 0 });
  } */
  /*  HiddenMenu() {
    let fixtime = this.state.fixtime;
    if (this.state.currentmenuindex != -1) {
      fixtime[this.state.currentmenuindex].hours[
        this.state.currentmenuindex2
      ].min[this.state.currentmenuindex3].showmenu = 0;
      this.setState({
        fixtime: fixtime,
        currentmenuindex: -1,
        currentmenuindex2: -1,
        currentmenuindex3: -1,
      });
    }
  } */
  addBook(bookid) {
    if (this.state.showbookPanel == 1 && this.state.newbook == 1) {
      this.setState({
        showbookPanel: 0,
        newbook: 0,
      });
      return;
    }
    let showbookPanel = 1;
    if (bookid != "") {
      //    fixedemployee=this.state.books[bookid].fixedemployee;

      let books = this.state.books;
      //  books[bookid].newserviceid = books[bookid].serviceid;
      //  books[bookid].newemployee = books[bookid].employee;
      //  books[bookid].newperiodno = books[bookid].periodno;
      books[bookid].newfromtime = books[bookid].fromtime;
      this.setState({
        newbook: 1,
        edititemid: "",
        books: books,
        serviceid: "",
        servicetext: "",
        serviceminute: 0,
        username: this.state.books[bookid].username,

        name:
          this.state.books[bookid].firstname +
          " " +
          this.state.books[bookid].lastname,
        usertel: this.state.books[bookid].telephone,
        tickettip: this.state.books[bookid].tickettip,
      });
    }

    this.setState(
      {
        showbookPanel: showbookPanel,
        newbook: 1,
      },
      () => {
        //   this.selectStuffMin(index, index2, index3, bookid, 1);
      }
    );
  }
  ShowTicket(index, index2, index3, bookid, timecheck,servicetitle) {
  
    if (this.state.isDragging2) return;
    // e.preventDefault();
    // document.getElementById('TicketFormDiv').style.display="block";

    if (timecheck == 0) {
      MyToastShow(this, 2, "Off time! ", 150, 80, "success", "addbranch");
      return;
    }
    let fixtime = this.state.fixtime;
    // if (used == 1) return;

    //有选择并且和以前选择不一样
    if (
      this.state.currentmenuindex != -1 &&
      (index != this.state.currentmenuindex ||
        index2 != this.state.currentmenuindex2 ||
        index3 != this.state.currentmenuindex3)
    ) {
      /*  fixtime[this.state.currentmenuindex].hours[
        this.state.currentmenuindex2
      ].min[this.state.currentmenuindex3].showmenu = 0; */
      /*  if (this.state.showcustumerPanel == 1)
        this.setState({
            showcustumerPanel: 0,
        }); */
    }

    //有选择和以前选择一样
    if (
      index == this.state.currentmenuindex &&
      index2 == this.state.currentmenuindex2 &&
      index3 == this.state.currentmenuindex3
    ) {
      /*    let val = (fixtime[index].hours[index2].min[index3].showmenu + 1) % 2;
      fixtime[this.state.currentmenuindex].hours[
        this.state.currentmenuindex2
      ].min[this.state.currentmenuindex3].showmenu = val; */
      /* if (this.state.showcustumerPanel == 1)
        this.setState({
           showcustumerPanel: 0,
        }); */
    } // else {
    //  fixtime[index].hours[index2].min[index3].showmenu = 1;
    // }
    let fixedemployee = 0;
    let showbookPanel = 1;
    if (bookid != "") {

      showbookPanel = 0;
      fixedemployee = this.state.books[bookid].fixedemployee;

      this.state.books[bookid].realpay = this.state.books[bookid].aftertaxprice;

      let books = this.state.books;
      //  books[bookid].newserviceid = books[bookid].serviceid;
      //  books[bookid].newemployee = books[bookid].employee;
      //  books[bookid].newperiodno = books[bookid].periodno;
      //  books[bookid].newfromtime = books[bookid].fromtime;
     // console.log(this.state.books[bookid]);
      //console.log(servicetitle);
      this.setState({
        books: books,
        serviceid: this.state.books[bookid].serviceid,
        servicetext:servicetitle,
        username: this.state.books[bookid].username,

        name:
          this.state.books[bookid].firstname +
          " " +
          this.state.books[bookid].lastname,
        usertel: this.state.books[bookid].telephone,
        tickettip: this.state.books[bookid].tickettip,
        eclaim_jine: this.state.books[bookid].eclaim_jine,
        serviceminute:this.state.newbook==1?this.state.books[bookid].minutes:0
      //  serviceminute:this.state.books[bookid].minutes
      });

      console.log("rebook or edit")
    }
    else {
      console.log("newbook")
      this.setState({
        username: "",
        name: "",
        usertel: "",
        serviceid: "",
        servicetext: "",
        schservicetitle: "",
      });
    }

    this.setState(
      {
        showbookPanel: showbookPanel,
        fixtime: fixtime,
        currentmenuindex: index,
        currentmenuindex2: index2,
        currentmenuindex3: index3,
        editid: bookid,

        fixedemployee: fixedemployee,
        edititemid: "",
        newbook: 1,
       
        servicelistshow: 0,
        filteredservicelist: [],
        error: ""
      },
      () => {
        this.selectStuffMin(index, index2, index3, bookid, 1);
      }
    );
  }

  selectStuffMin(index, index1, index2, bookid, val) {
    /*  if (used == 1) {
      MyToastShow(
        this,
        2,
        "不可选择已占用的时间",
        200,
        120,
        "fail",
        "addemployee"
      );
      return;
    } */

    let bookdata = this.state.bookdata;
    // let error = "";

    /* if (
    bookdata.employee != "" &&
    bookdata.employee != ele.state.fixtime[index].username &&
    bookdata.mins.length > 0
  ) {
    error = "You should select only one employee.";
  } else if (
    bookdata.periodno != "" &&
    bookdata.periodno != ele.state.fixtime[index].periodno &&
    bookdata.mins.length > 0
  ) {
    error = "You should select only one day.";
  } */
    /*   if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      return;
    } */
    let fixtime = this.state.fixtime;

    let hours = this.state.fixtime[index].hours;
    /*   let selected = hours[index1].min[index2].s;
  if (selected == null) selected = 0;
  selected = (selected + 1) % 2;
 */
    //hours[index1].min[index2].s = selected;

    let v = hours[index1].min[index2].v;
    if (v == 0) v = "00";
    let _value = hours[index1].v + ":" + v;

    bookdata.employee = this.state.fixtime[index].username;
    bookdata.periodno = this.state.fixtime[index].periodno;
    bookdata.nickname = this.state.fixtime[index].zsxm;
    bookdata.fromtime = _value;
    if (bookdata.nickname == "") {
      bookdata.nickname = bookdata.employee;
    }

    /*   if (selected == 1) {
    bookdata.mins.push(_value);
  } else {
    bookdata.mins = bookdata.mins.filter((fruit) => fruit !== _value);
  } */
    // bookdata.mins = [];
    // bookdata.mins.push(_value);
    // bookdata.mins.splice(hours[index1].v + ":" + hours[index1].min[index2].v); //bookdata.mins.splice(hours[index1].v + ":" + element.v);

    //bookdata.employeeusername = ele.state.months[index].username;
    this.setState({
      fixtime: fixtime,
      bookdata: bookdata,
      // showcustumerPanel: val,
      showticketPanel: val,
    });
    //console.log(days);
  }
  selCust(username, zsxm, telephone) {
    this.setState({
      schusername: "",
      username: username,
      name: zsxm,
      telephone: telephone,
      usertel: telephone,
      customerlist: [],
    });
  }

  ShowServiceList(val) {
    this.setState({
      servicelistshow: val,
      postresult: "",
      filteredservicelist: [],
      schservicetitle: "",
    });
  }
  selService(id, title, minute) {
    this.setState({
      serviceid: id,
      servicetext: title,
      serviceminute: minute,
      filteredservicelist: [],
      schservicetitle: "",
      servicelistshow: 0,
    });
  }
  searchService(e) {
    let val = e.target.value;
    let olddata = this.state.schservicetitle;
    this.setState({
      schservicetitle: val,
    });
    if (val == "") {
      this.setState({
        filteredservicelist: [],
      });
    }
    if (olddata.length < val.length && val.length >= 2) {
      val = val.trim();

      let filteredservicelist = [];


      filteredservicelist = this.state.servicelist.flatMap(item =>
        item.child.filter(child => child.title.toLowerCase().includes(val.toLowerCase())));



      /*     this.state.servicelist.forEach((element, j) => {
            let _item = element.child.find((item3) =>
              item3.title.toLowerCase().includes(val.toLowerCase())
            );
            if (_item != null) {
              filteredservicelist.push(_item);
            }
          }); */

      if (filteredservicelist.length > 0) {
        this.setState({
          filteredservicelist: filteredservicelist,
          // totalpage: result.num,
        });
      }
    }
  }
  searchCust(e) {

    let val = e.target.value;
    let olddata = this.state.schusername;
    this.setState({
      schusername: val,
    });

    if (val == "") {
      this.setState({
        customerlist: [],
      });
    }

    val = val.trim();
    if (olddata.length < val.length && val.length >= 3) {


      const { myusername, ukey } = this.props;
      var geturl =
        AJAROOT +
        "/getaja/xx.aspx?u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "&telephone=" +
        val +
        "&action=schcustomer";

      axios.get(geturl).then((res) => {
        const result = res.data;

        this.setState({
          customerlist: result.list,
          // totalpage: result.num,
        });
      });
    }
  }

  checkHours(mins) {
    let data = this.state.bookdata;

    // data.mins.sort();

    const sortedTimes = mins.sort((a, b) => {
      const [aHour, aMinute] = a.split(":").map(Number); // 将时间字符串拆分为小时和分钟
      const [bHour, bMinute] = b.split(":").map(Number);
      return aHour - bHour || aMinute - bMinute; // 比较小时和分钟并返回排序结果
    });

    let error = "";
    if (mins.length == 0) {
      if (this.state.editid == "") error = "Please select time";
      else if (this.state.editid != "" && this.state.timepanel == 1)
        error = "Please select time or return";
    } else if (data.periodno == "") error = "Please select date";
    else if (data.employee == "") error = "Please select employee";

    if (error == "") {
      //   let employeeitem = this.state.employeelist.find();

      /*  let _item = this.state.employeelist.find(
        (item3) => item3.username === data.employee
      );
      if (_item != null) {
        this.state.bookdata.nickname = _item.zsxm;
      }
 */
      if (mins.length > 0) {
        let timecontinue = true;
        let totalminutes = 0;
        let lasttime = "";
        mins.forEach((element, j) => {
          totalminutes += 10;
          let timestr = getDateFromPeriodno(data.periodno) + " " + element;

          let now = new Date(timestr);
          if (lasttime != "") {
            var d = now - lasttime;

            //  var h = Math.floor(d / 3600000);
            // console.log(h);
            // lasttime = new Date();
            var m = Math.floor(d / 60000);
            //  console.log(timestr);
            //  console.log(lasttime);
            //  console.log(m);
            if (m > MinDawai) {
              timecontinue = false;
            }
          }
          lasttime = now;
        });

        if (!timecontinue) error = "Please select continue time";
        else if (totalminutes < this.state.serviceminutes)
          error =
            "Please select at least " +
            this.state.serviceminutes +
            " minutes totally";
        //检查时间是否>=serviceminute
      }
    }

    return error;
  }
  /* 
  SaveTime() {
    // const { myusername, ukey } = this.props;
    let error = this.checkHours();

    let nickname = this.state.bookdata.nickname;
    if (nickname == "undefined" || nickname == undefined || nickname == null)
      nickname = this.state.bookdata.employee;
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
    } else {
      let data = {
        nickname: nickname,
        employee: this.state.bookdata.employee,
        periodno: this.state.bookdata.periodno,
        mins: this.state.bookdata.mins,
      };

      this.setState({
        timepanel: 0,
        savedbookdata: data,
      }); 
    }
  } */
  setCheckStatus(bookid, value) {
    let books = this.state.books;
    books[bookid].newchecked = value;
    this.setState({
      books: books,
    });
  }
  setrange(val) {
    let _date = new Date();
    _date = new Date(_date.setDate(1));
    if (val == 7) {
      _date = new Date(_date.setDate(_date.getDate() + val));
    } else if (val == "1month") {
      _date = new Date(_date.setMonth(_date.getMonth() + 1));
    } else if (val == "3month") {
      _date = new Date(_date.setMonth(_date.getMonth() + 3));
    } else if (val == "6month") {
      _date = new Date(_date.setMonth(_date.getMonth() + 6));
    } else if (val == "1year") {
      _date = new Date(_date.setFullYear(_date.getFullYear() + 1));
    }
    _date = new Date(_date.setDate(_date.getDate() - 1));
    this.setState({
      schtodate: _date,
    });
  }
  setFixed(val) {
    this.setState({
      fixdate: val,
    });
  }
  getWeeks() {
    let w = [
      { v: 1, check: 0 },
      { v: 2, check: 0 },
      { v: 3, check: 0 },
      { v: 4, check: 0 },
      { v: 5, check: 0 },
      { v: 6, check: 0 },
      { v: 7, check: 0 },
    ];
    return w;
  }
  getDays() {
    let days = [
      { v: 1, check: 0 },
      { v: 2, check: 0 },
      { v: 3, check: 0 },
      { v: 4, check: 0 },
      { v: 5, check: 0 },
      { v: 6, check: 0 },
      { v: 7, check: 0 },
      { v: 8, check: 0 },
      { v: 9, check: 0 },
      { v: 10, check: 0 },
      { v: 11, check: 0 },
      { v: 12, check: 0 },
      { v: 13, check: 0 },
      { v: 14, check: 0 },
      { v: 15, check: 0 },
      { v: 16, check: 0 },
      { v: 17, check: 0 },
      { v: 18, check: 0 },
      { v: 19, check: 0 },
      { v: 20, check: 0 },
      { v: 21, check: 0 },
      { v: 22, check: 0 },
      { v: 23, check: 0 },
      { v: 24, check: 0 },
      { v: 25, check: 0 },
      { v: 26, check: 0 },
      { v: 27, check: 0 },
      { v: 28, check: 0 },
    ];
    return days;
  }
  searchDate() {
    const { myusername, ukey } = this.props;

    var error = "";
    var action = "fixtime";

    action = "rangetime";
    if (this.state.schfromdate == "" || this.state.schtodate == "")
      error = "Please input from or end date";

    this.setState({
      checkerror: error,
    });
    if (error != "") return;

    //if (this.state.schfromdate == "" || this.state.schtodate == "") return;
    const date1 = new Date(this.state.schfromdate);
    const date2 = new Date(this.state.schtodate);
    let monthdiff =
      date2.getFullYear() * 12 +
      date2.getMonth() -
      (date1.getFullYear() * 12 + date1.getMonth());
    let months = [];

    for (let j = 0; j <= monthdiff; j++) {
      let _days = this.getDays();
      let _date = new Date(
        new Date(this.state.schfromdate).setMonth(date1.getMonth() + j)
      );

      let M = _date.getMonth() + 1;
      let year = _date.getFullYear();
      let month = year;
      if (M <= 9) month += "-0" + M;
      else month += "-" + M;
      //   let schtodate = _date.getFullYear() + "-" + (_date.getMonth() + 1);

      // let arr = month.split("-");

      // let yearno = arr[0] * 1;
      // let monthno = arr[1] * 1;
      // let maxday = 30;
      if (
        M == 1 ||
        M == 3 ||
        M == 5 ||
        M == 7 ||
        M == 8 ||
        M == 10 ||
        M == 12
      ) {
        _days.push({ v: 29, check: 0 });
        _days.push({ v: 30, check: 0 });
        _days.push({ v: 31, check: 0 });
      } else if (M == 2) {
        if (year % 4 == 0) {
          _days.push({ v: 29, check: 0 });
        }
      } else {
        _days.push({ v: 29, check: 0 });
        _days.push({ v: 30, check: 0 });
        //  days.push({ v: 31, check: 0 });
        //  maxday = 31;
      }

      months.push({
        v: month,
        check: 0,
        days: _days,
        weeks: this.getWeeks(),
      });
    }
    this.setState({
      months: months,
    });

    let fromdate = "";
    let todate = "";

    if (this.state.schfromdate != "") {
      fromdate =
        this.state.schfromdate.getFullYear() +
        "-" +
        (this.state.schfromdate.getMonth() + 1) +
        "-" +
        this.state.schfromdate.getDate();
    }

    if (this.state.schtodate != "") {
      todate =
        this.state.schtodate.getFullYear() +
        "-" +
        (this.state.schtodate.getMonth() + 1) +
        "-" +
        this.state.schtodate.getDate();
    }
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=" +
      action +
      "&fromdate=" +
      fromdate +
      "&todate=" +
      todate +
      "&stuff=" +
      this.state.selectedemployee +
      "";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      /*  if (this.state.fixdate == 1) {
        let fixtime = result.list;

        //  fixtime = [...result.list, ...result.list, ...result.list];
        this.setState({
          fixtime: fixtime,
          avarangedate: [],

        
          employeeusername: this.state.selectedemployee,
        });
      }  */
      let _usertype1book_periodnos=[];
      if(result.hasOwnProperty("usertype1book_periodnos"))
        _usertype1book_periodnos=result.usertype1book_periodnos
      this.setState(
        {
          avarangedate: result.list,
          //  fixtime: [],
          //bookdata: { employee: "", periodno: "", mins: [] },
          employeeusername: this.state.selectedemployee,
          usertype1book_periodnos:_usertype1book_periodnos
        },
        () => {
          //this.checkDay(this.state.selectedday, 1);
        }
      );
      // this.setState({}); /**/
    });
  }
  setStartDate(val) {
    let date = "";
    if (val == null) date = "";
    else {
      let _date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      date = _date;
    }
    this.setState({
      schfromdate: date,
    });
  }
  setToDate(val) {
    let date = "";
    if (val == null) date = "";
    else {
      let _date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      date = _date;
    }
    this.setState({
      schtodate: date,
    });
  }
  setDate(val, field) {
    let date = "";
    if (val == null) return;

    if (field == "periodno") {
      let bookdata = this.state.bookdata;
      // console.log(bookdata.periodno);
      let periodno = getPeriodnoFromDate(val);
      bookdata.periodno = periodno;
      this.setState({
        bookdata: bookdata,
      });
    }
  }
  setEmployeeUsername(e) {
    const text = e.target.options[e.target.selectedIndex].text;
    let val = e.target.value;
    this.setState({
      selectedemployee: val,
    });
    // console.log(val);
    let url = '/user/employee/worktime?username=' + val + '';
    window.open(url);
    // document.location=;
  }
  showEmployee(val) {
    if (this.state.serviceid == "") {
      MyToastShow(
        this,
        2,
        "Please select service",
        200,
        120,
        "fail",
        "addemployee"
      );
    } else {
      if (val == 1) {
        document.body.style.overflow = "hidden";
        let selectedday = "";
        if (this.state.savedbookdata.periodno != "") {
          let _periodno = this.state.savedbookdata.periodno;
          selectedday = _periodno;
          selectedday =
            _periodno.substring(0, 4) +
            "-" +
            _periodno.substring(4, 6) +
            "-" +
            _periodno.substring(6) * 1;
        }

        this.setState(
          {
            selectedday: selectedday,
            timepanel: val,
          },
          () => {
            //   this.checkTime();

            if (selectedday != "") {
              this.checkDay(selectedday, 0);
            }
          }
        );
      } else {
        document.body.style.overflow = "auto";
        this.setState({
          timepanel: val,
          selectedday: "",
        });
      }
    }
  }
  checkDay(day, loadsameday, showticketPanel = 0, showbookPanel = 0) {
    // console.log("day"+day);
    //console.log("selectedday"+this.state.selectedday);
    //console.log("loadsameday"+loadsameday);
    if (day == this.state.selectedday && loadsameday == 0) return;
    const { myusername, ukey } = this.props;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=fixtime&fromdate=" +
      day +
      "&stuff=" +
      this.state.selectedemployee +
      "";
      console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);

      // result.booklist=JSON.parse(jsonString);
      if (result.r == 1) {
        let _bookdata = { employee: "", periodno: "", mins: [], nickname: "" };
        //  if (auto == 1) _bookdata = this.state.savedbookdata;
        ///document.getElementById("scrollable").fo
        /* */
       // console.log(result);
        this.setState(
          {
            
            selectedday: day,
            fixtime: result.list,
            bookdata: _bookdata,
            showticketPanel: showticketPanel,
            showbookPanel: showbookPanel,
            rebook: 0,
            books: result.books,
          //  usertype1book_periodnos:result.usertype1book_periodnos
            //avarangedate: [],
            //  employeeusername: this.state.selectedemployee,
          },
          () => {
            /*    setTimeout(() => {
   if(document.getElementById("scrollable"))
{
document.getElementById("scrollable").focus();
}
  }, 1000); */

            if (loadsameday > 1) {
              //   this.handleContextMenu(0, 0, 0, loadsameday);
            }
          }
        );
      }
      // this.setState({}); /**/
    });
  }
  /*   setDate(val) {
    let periodno = "";
    if (val == null) periodno = "";
    else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      periodno = date;
    }

    this.setState({
      periodno: periodno,
    });
  } */
  FocusField(e, field) {
    let val = e.target.value;
    if (field == "tickettip") {
      let books = this.state.books;
      if (val + "" == "0") {
        books[this.state.editid].tickettip = "";
        this.setState({
          books: books,
        });
      } else return;
    }
    else if (field == "eclaim_jine") {
      let books = this.state.books;
      if (val + "" == "0") {
        books[this.state.editid].eclaim_jine = "";
        this.setState({
          books: books,
        });
      } else return;
    } else if (field == "realpay") {
      let books = this.state.books;
      if (val + "" == "0") {
        books[this.state.editid].realpay = "";
        this.setState({
          books: books,
        });
      } else return;
    }
  }
  BlurField(e, field) {
    let val = e.target.value;
    if (field == "serviceminute") {
      if (val != "") {
        if (val % 5 > 0) {
          MyToastShow(
            this,
            2,
            "Please enter a multiple of 5 or 10. ",
            150,
            100,
            "success",
            "addbranch"
          );
          this.setState({
            serviceminute: "",
          });
        }
      }
    }
  }
  changeField(e, field) {

    let val = e.target.value;
    //  console.log(field);
    if (field == "username")
      this.setState({
        username: val,
      });
    else if (field == "userremark") {
      let books = this.state.books;
      //   console.log(val);
      books[this.state.editremarkbookid].userremark = val;

      this.setState({
        books: books,
      });
    } else if (field == "name")
      this.setState({
        name: val,
      });
    else if (field == "serviceminute") {
      if (isNaN(val)) {
        return;
      }

      this.setState({
        serviceminute: val,
      });
    } else if (field == "tickettip") {
      if (isNaN(val)) {
        return;
      }
      let books = this.state.books;
      books[this.state.editid].tickettip = val;

      this.setState({
        books: books,
      });
    }
    else if (field == "eclaim_jine") {
      if (isNaN(val)) {
        return;
      }
      let books = this.state.books;
      books[this.state.editid].eclaim_jine = val;

      this.setState({
        books: books,
      });
    }
    else if (field == "realpay") {
      if (isNaN(val)) {
        return;
      }
      let books = this.state.books;
      books[this.state.editid].realpay = val;

      this.setState({
        books: books,
      });
    } else if (field == "service") {
      if (val == "") {
        this.setState({
          selectedservice: "",
          serviceid: "",
          servicetext: "",
          serviceminute: 0,
        });
      } else {
        let id = "";
        let servicetext = "";

        let minutes = 0;

        this.state.servicelist.forEach((element, j) => {
          let _item = element.child.find((item3) => item3.id === val);

          if (_item != null) {
            id = _item.id;
            servicetext = _item.title;
            minutes = _item.minutes;
          }
        });

        /*  let arr = val.split("|");
        let index = arr[0] * 1;
        let index2 = arr[1] * 1;
        //   const text = e.target.options[e.target.selectedIndex].text;
        let id = this.state.servicelist[index].child[index2].id;
        let servicetext = this.state.servicelist[index].child[index2].title;

        let minutes = this.state.servicelist[index].child[index2].minutes * 1; */

        this.setState({
          selectedservice: val,
          serviceid: id,
          servicetext: servicetext,
          serviceminute: minutes,
        });
      }
    } else if (field == "telephone") {
      this.setState({
        telephone: val,
      });
    } else if (field == "remark")
      this.setState({
        remark: val,
      });
    else if (field == "newtelephone") {
      this.setState({
        newtelephone: val,
      });
    } else if (field == "ticketremark") {
      let books = this.state.books;
      books[this.state.editid].ticketremark = val;

      this.setState({
        books: books,
      });
    } else if (field == "newlastname") {
      if(val.length==1)val= val.toUpperCase();
      this.setState({
        newlastname: val,
      });
    } else if (field == "newfirstname") {
      
      if(val.length==1)val= val.toUpperCase();
      this.setState({
        newfirstname: val,
      });
    } else if (field == "employee") {
      let bookdata = this.state.bookdata;

      bookdata.employee = val;

      let _item = this.state.employeelist.find(
        (item3) => item3.username === val
      );

      if (_item != null) {
        bookdata.nickname = _item.firstname + "";
        if (_item.lastname != "") bookdata.nickname += " " + _item.lastname;
      }

      this.setState({
        bookdata: bookdata,
      });
    } else if (field == "hour") {
      let hour = val;
      if (hour <= 9) hour = "0" + hour * 1;
      /*    if (this.state.newbook == 1) {
        let bookdata = this.state.bookdata;
        let fromtime = bookdata.mins[0];

        bookdata.mins[0] = hour + ":" + fromtime.split(":")[1];

        this.setState({
          bookdata: bookdata,
        });
      } else */ {
        let bookdata = this.state.bookdata;

        let fromtime = bookdata.fromtime;

        if (fromtime == null) fromtime = this.state.defaultfromtime;
        bookdata.fromtime = hour + ":" + fromtime.split(":")[1];

        this.setState({
          bookdata: bookdata,
        });
      }
      //  this.timeRef.current.blur();
    } else if (field == "minute") {
      let minute = val;
      if (minute <= 9) minute = "0" + minute * 1;
      /*  if (this.state.newbook == 1) {
        let bookdata = this.state.bookdata;
        let fromtime = bookdata.mins[0];

        bookdata.mins[0] = fromtime.split(":")[0] + ":" + minute;

        this.setState({
          bookdata: bookdata,
        });
      } else */ {
        let bookdata = this.state.bookdata;

        let fromtime = bookdata.fromtime;

        if (fromtime == null) fromtime = this.state.defaultfromtime;
        bookdata.fromtime = fromtime.split(":")[0] + ":" + minute;

        this.setState({
          bookdata: bookdata,
        });
      }
      //  this.timeRef.current.blur();
    } else if (field == "fixedemployee") {
      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }

      this.setState({
        fixedemployee: val,
      });
    }
  }

  savebookcol(col) {
    const { myusername, ukey } = this.props;
    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //

    let error = "";
    let val = "";
    /*     if (this.state.bookdata.nickname == "")
      error = "Please select time and save"; */
    if (col == "tickettip") {
      if (this.state.books[this.state.editid].tickettip == "")
        error = "Please fill in tip";
      else val = this.state.books[this.state.editid].tickettip;
    } else if (col == "realpay") {
      if (this.state.books[this.state.editid].realpay == "")
        error = "Please fill in money";
      else val = this.state.books[this.state.editid].realpay;
    }
    // else error = this.checkHours();
    if (col == "checkout") {
      val = (this.state.books[this.state.editid].status * 1 + 1) % 2;
      col = "status";
    }
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      this.setState({
        postresult: error,
      });
      return;
    }
    this.setState({
      postresult: "Waiting....",
    });

    const postdata = {
      uid: myusername,
      id: this.state.editid,
      // bookid: this.state.editid,
      col: col,
      val: val + "",
    };

    // return;
    const that = this;
    //if (this.state.bookdata.nickname == "") error = "Please select time";
    //else if (this.state.telephone === "") error = "Please fill in telephone";

    // else if (this.state.password == "") error = "Please fill in password";
    // else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=bookcoledit&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          var r = res.r;
          // console.log(res);
          if (r === 1) {
            //  that.checkDay(getDateFromPeriodno(that.state.bookdata.periodno), 1);
            //  console.log(that.state.selectedday);
            ///  console.log(getDateFromPeriodno(that.state.bookdata.periodno));
            //  this.postresult = "登陆成功！";
            MyToastShow(
              that,
              2,
              "Successfully! ",
              150,
              80,
              "success",
              "addbranch"
            );
            let books = that.state.books;
            books[that.state.editid][col] = val;
            that.setState({
              postresult: "",
              books: books,

            });


            if (col == "status") {
              that.CloseTickeForm();
            }

            //that.props.history.push("/user/book/list");
          } else {
            MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
            //document.getElementById("result2").style.color = "red";
            that.setState({
              postresult: "",
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) { });
    }
  }
  setJine(jine) {
    let books = this.state.books;
    books[this.state.editid].realpay = jine;
    this.setState({
      books: books,
    });
  }
  CloseTickeForm() {
    this.setState({
      showticketPanel: 0,
      rebook: 0,
    })

  }
  savetricketcol(col) {



    const { myusername, ukey } = this.props;
    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //

    let error = "";
    let val = "";
    /*     if (this.state.bookdata.nickname == "")
      error = "Please select time and save"; */
    /*     if (col == "tickettip") {
     col = "tip";
      if (this.state.books[this.state.editid].tickettip == "")
        error = "Please fill in tip";
      else val = this.state.books[this.state.editid].tickettip;
    } else  */
    if (col == "realpay") {
      if (this.state.books[this.state.editid].realpay == "") val = 0;
      else val = this.state.books[this.state.editid].realpay;
    } else if (col == "customer") {
      col = "username";
      if (this.state.username == "") error = "Please select customer";
      else val = this.state.username;
    } else if (col == "ticketremark") {
      val = this.state.books[this.state.editid].ticketremark;
      col = "remark";
    } /* else if (col == "checkout") {
      val = (this.state.books[this.state.editid].status * 1 + 1) % 2;
      col = "status";
    } */


    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      this.setState({
        postresult: error,
      });
      return;
    }
    this.setState({
      postresult: "Waiting....",
    });

    let postdata = {
      uid: myusername,
      id: this.state.books[this.state.editid].ticketid,
      // bookid: this.state.editid,
      col: col,
      val: val + "",
    };

    if (col == "realpay") {
      postdata.paymethod = this.state.books[this.state.editid].paymethod;
      let tip = this.state.books[this.state.editid].tickettip;
      if (this.state.books[this.state.editid].tickettip == "") tip = 0;
      postdata.tip = tip + "";

      let eclaim_jine = this.state.books[this.state.editid].eclaim_jine;
      if (this.state.books[this.state.editid].eclaim_jine == "") eclaim_jine = 0;
      postdata.eclaim_jine = eclaim_jine + "";
    }
    //console.log(postdata);

    // return;
    const that = this;
    //if (this.state.bookdata.nickname == "") error = "Please select time";
    //else if (this.state.telephone === "") error = "Please fill in telephone";

    // else if (this.state.password == "") error = "Please fill in password";
    // else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=setticketcol&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {

        var r = res.r;
        // console.log(res);
        if (r === 1) {
          if (col !== "status") {
            that.checkDay(
              getDateFromPeriodno(that.state.books[that.state.editid].periodno),
              1,
              1,
              0
            );
          }
          /* 
  */

          //  console.log(that.state.selectedday);
          ///
          //  this.postresult = "登陆成功！";

          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          let books = that.state.books;
          books[that.state.editid][col] = val;
          that.setState({
            postresult: "",
            books: books,
          });
          if (col == "status") {
            that.CloseTickeForm();
          }



          //that.props.history.push("/user/book/list");
        } else {
          MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: "",
          });
          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) { });


  }
  saveBook() {//编辑或添加服务
    if (this.state.posting) return;
    let error = "";
    /*     if (this.state.bookdata.nickname == "")
      error = "Please select time and save"; */
    if (this.state.username == "" && this.state.schusername == "")
      error = "Please select customer";
    // else error = this.checkHours();
    else if (this.state.serviceid == "") error = "Please select service";
    //else if (this.state.serviceminute == "") error = "Please fill in minutes";
    else if (this.state.serviceminute != "") {
      //  if (this.state.serviceminute * 1 == 0) error = "Please fill in minutes";
      if ((this.state.serviceminute * 1) % 5 > 0) {
        error = "The minutes should as a multiple of 5 or 10.";
      }
    }

    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      this.setState({
        postresult: error,
      });
      return;
    }

    if (this.state.editid != "") {
      if (this.state.rebook == 0 && this.state.newbook == 0) {
        this.editBook();
        return;
      }
    }
    const { myusername, ukey } = this.props;
    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //

    this.setState({
      postresult: "Waiting....",
      posting: true,
    });

    // document.getElementById("result2").innerHTML = "正在提交....";
    //   let dianscope = this.state.selectedbranch;
    let fromtime = "";

    fromtime = this.state.bookdata.fromtime;
    if (fromtime == null) {
      fromtime = this.state.defaultfromtime;
    }
    /*  if(this.state.newbook==0)
    {
    fromtime=this.state.bookdata.fromtime;
if(fromtime==null)
{
fromtime=this.state.defaultfromtime;
}
    }
    else
    {
    let mins = [];
    mins.push(this.state.bookdata.mins[0]);

    let time1 = this.state.bookdata.mins[0];
    let arr = time1.split(":");
    let num = Math.ceil(this.state.serviceminutes / MinDawai);
    let firsttime = new Date(2022, 1, 1, arr[0], arr[1]);
     fromtime = firsttime.getHours() + ":" + firsttime.getMinutes();
     for (var j = 1; j < num; j++) {
      firsttime.setTime(firsttime.getTime() + MinDawai * 60 * 1000);

      let _time = firsttime.getHours() + ":" + firsttime.getMinutes();
      // if (j == num - 1 && lasttime != firsttime) {
      //  _time = firsttime.getHours() + ":" + lasttime.getMinutes();
      //  }
      mins.push(_time);
    }
    error += this.checkHours(mins);
    } */
    /*   let lasttime = new Date(2022, 1, 1, arr[0], arr[1]);
    lasttime.setTime(
      lasttime.getTime() + this.state.serviceminutes * 60 * 1000
    ); */

    // console.log(lasttime);

    let ticketid = "";
    if (this.state.editid != "") {
      ticketid = this.state.books[this.state.editid].ticketid;
      if (this.state.rebook == 1) ticketid = "";
    }
    let newservice=0;
    if(this.state.newbook!="")newservice=this.state.newbook;
    const postdata = {
      uid: myusername,
      // bookid: this.state.editid,
      customer: this.state.username,
      telephone: this.state.schusername,
      //  telephone: this.state.telephone,
      serviceid: this.state.serviceid,
      employee: this.state.bookdata.employee,

      periodno: this.state.bookdata.periodno,
      fixedemployee: this.state.fixedemployee,
      //  hours: mins,
      fromtime: fromtime,
      serviceminute: this.state.serviceminute * 1,
      //serviceminutes: this.state.serviceminutes,
      //  birthday: birthday,
      remark: this.state.remark,
      ticketid: ticketid,
      bookid:this.state.editid,
      newservice:newservice
    };

    // console.log(postdata);
    // return;
    //return;
    //return;
    const that = this;
    //if (this.state.bookdata.nickname == "") error = "Please select time";
    //else if (this.state.telephone === "") error = "Please fill in telephone";

    // else if (this.state.password == "") error = "Please fill in password";
    // else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=savebook&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === 1) {
          that.checkDay(getDateFromPeriodno(that.state.bookdata.periodno), 1);
          //  console.log(that.state.selectedday);
          ///  console.log(getDateFromPeriodno(that.state.bookdata.periodno));
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );

          /*    this.setState({
               schusername: "",
               username: username,
               name: zsxm,
               telephone: telephone,
               usertel: telephone,
               customerlist: [],
             });
    */
          that.setState({
            postresult: "",
            posting: false,
            //   username: "",
            //  schusername: "",      
            //  name: "",
            //  telephone: "",
            //  usertel:"",              
            // editid:
          });

          //that.props.history.push("/user/book/list");
        } else {
          MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: "",
            posting: false,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        MyToastShow(that, 2, "network error", 200, 120, "fail", "addemployee");
        that.setState({
          postresult: "Error",
          posting: false,
        });
      });
  }

  editBook() {
    const { myusername, ukey } = this.props;

    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //
    /* let error = "";
    
    if (this.state.username == "" && this.state.schusername == "")
      error = "Please select customer or input telephone";
    // else error = this.checkHours();
    else if (this.state.serviceid == "") error = "Please select service";
    if (error != "") {
      //  MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      this.setState({
        postresult: error,
      });
      return;
    } */
    this.setState({
      postresult: "Waiting....",
      posting: true,
    });

    // document.getElementById("result2").innerHTML = "正在提交....";
    //   let dianscope = this.state.selectedbranch;

    /*   let lasttime = new Date(2022, 1, 1, arr[0], arr[1]);
    lasttime.setTime(
      lasttime.getTime() + this.state.serviceminutes * 60 * 1000
    ); */

    // console.log(lasttime);
    let fromtime = "";
    if (this.state.bookdata.fromtime) fromtime = this.state.bookdata.fromtime;
    const postdata = {
      uid: myusername,
      // bookid: this.state.editid,
      customer: this.state.username,

      //  telephone: this.state.telephone,
      serviceid: this.state.serviceid,

      //  hours: mins,
      bookid: this.state.edititemid * 1,
      // tip: this.state.tip + "",
      //新增
      // fromtime: this.state.books[this.state.editid].fromtime,
      fromtime: fromtime,
      periodno: this.state.bookdata.periodno,
      employee: this.state.bookdata.employee,
      fixedemployee: this.state.fixedemployee * 1,
      serviceminute: this.state.serviceminute * 1,
      //serviceminutes: this.state.serviceminutes,
      //  birthday: birthday,
    };

    // console.log(postdata);

    const that = this;
    //if (this.state.bookdata.nickname == "") error = "Please select time";
    //else if (this.state.telephone === "") error = "Please fill in telephone";

    // else if (this.state.password == "") error = "Please fill in password";
    // else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";
    let posturl =
      AJAROOT +
      "/postaja/savexx.aspx?action=saveeditbook&u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";

    $.ajax({
      type: "POST",
      dataType: "json",
      url: posturl,
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
     
        var r = res.r;

        if (r === 1) {
          that.checkDay(
            getDateFromPeriodno(that.state.bookdata.periodno),
            1,
            1,
            0
          );
          //  console.log(that.state.selectedday);
          ///  console.log(getDateFromPeriodno(that.state.bookdata.periodno));
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            postresult: "",
            posting: false,
          });

          //that.props.history.push("/user/book/list");
        } else {
          MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: "",
            posting: false,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        MyToastShow(that, 2, "network error", 200, 120, "fail", "addemployee");
        //document.getElementById("result2").style.color = "red";
        that.setState({
          postresult: "",
          posting: false,
        });
      });
  }

  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { id, username, name, telephone } = queryString.parse(
      this.props.location.search
    );
    if (username == undefined) username = "";
    if (name == undefined) name = "";

    if (telephone == undefined) telephone = "";
    if (id == undefined) id = "";
    if (id == null) id = "";
    var title = "Add Booking";
    if (id != "") {
      title = "Edit Ticket";
    }
    document.title = title;

    // this.getBranchData(myusername, encodeURIComponent(ukey));
    this.getService(myusername, encodeURIComponent(ukey));
    // getUserIndexData(this, myusername, ukey);

    this.getEmployeeData(myusername, encodeURIComponent(ukey));

    let nextweek = new Date(new Date().setDate(1));

    nextweek = new Date(nextweek.setMonth(nextweek.getMonth() + 3));
    //  let periodno = new Date(today);
    nextweek = new Date(nextweek.setDate(nextweek.getDate() - 1));

    this.setState(
      {
        title: title,
        editid: id,
        username: username,
        usertel: telephone,
        telephone: telephone,
        name: name,
        //  periodno: today,
        schtodate: nextweek,
        selectedday: this.state.today,
      },
      () => {
        this.searchDate();
        if (id != "") this.getData(myusername, encodeURIComponent(ukey), id);
        else {
          this.checkDay(this.state.today, 1);
        } // this.checkDay(this.state.today, 1);
      }
    );
    //  MyToastShow(this, 200, "Successfully! ", 150, 80, "success", "addgroup");
  }
  changePaymethod(ref) {
    // console.log(ref.target.value);
    let books = this.state.books;

    books[this.state.editid].paymethod = ref.target.value;
    this.setState({
      books: books,
    });
  }
  getService(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=bookservicelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        servicelist: result.list,
      });
      // this.setState({}); /**/
    });
  }
  getEmployeeData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&opened=1&getset=1&action=employeelist";

    axios.get(geturl).then((res) => {
      const result = res.data;
   
      this.setState({
        employeelist: result.list,
        sys_mingrid_height: result.sys_mingrid_height,
      });
      // this.setState({});
    });
  }

  resetPos(divid, index1, index2, index3) {
    var draggable = document.getElementById(divid);
    let result_arr = this.getNewPos(draggable, index1, index2, index3);

    if (result_arr) {
      let newdivid = result_arr[3];
      document.getElementById(newdivid).style.backgroundColor = "#fff";


    }

    this.resetClicked = true;
    this.setState({
      isDragging2: false,

      dragingbookid: 0,
      /*   pubdowndraging: true,
      isDragging2: false,
    
      left2: 50,
      top2: 0, */
      //   orileft2: 50,
      // oritop2: 0,
    });



    //console.log(draggable.style.left);
    draggable.style.left = "0px";
    draggable.style.top = "0px";



  }
  mousemoveBook(event, divid, bookid) {
    if(this.props.myusertype*1==1)return;
    event.preventDefault();
    if (this.isxiala) return;

    if (this.resetClicked) {
      if (this.state.isDragging2) return;
      else this.resetClicked = false;
    }

    if (this.state.showuserremark == 1) return;
    if (this.state.isDragging2 && this.state.dragingbookid != bookid) return;

    var draggable = document.getElementById(divid);
    //console.log("draggable.offsetHeight" + draggable.offsetHeight);
    // console.log("event.offsetY" + event.nativeEvent.offsetY);
    //   console.log(event);
    if (draggable.offsetHeight - event.nativeEvent.offsetY < 15) {
      draggable.style.cursor = "n-resize";
      // console.log("11");
      // this.isxiala=true;
    } else if (draggable.style.cursor != "move") {
      //  console.log("changed......");
      draggable.style.cursor = "move";
      //  console.log("22");
    }
    //  else console.log("moving");
  }
  /* mouseRelease(event, divid, bookid)
  {
    
  } */
  dragBook(event, divid, bookid, index1, index2, index3) {
    if(this.props.myusertype*1==1)return;
    if (this.state.editremarkbookid != bookid && this.state.showuserremark == 0) event.preventDefault();
    if (this.resetClicked) {
      if (this.state.isDragging2) return;
      else this.resetClicked = false;
    }

    if (this.state.showuserremark == 1) return;
    if (this.state.isDragging2 && this.state.dragingbookid != bookid) return;
    var draggable = document.getElementById(divid);

    draggable.style.zIndex = 100;

    let that = this;

    var startY, startHeight, startX, startWidth;
    //var originalX, originalY;

    // originalX = draggable.offsetLeft;
    //originalY = draggable.offsetTop;

    // 保存 div 块原来的高度和鼠标相对于 div 块的垂直偏移量
    startHeight = draggable.offsetHeight;
    startY = event.clientY - draggable.offsetTop;

    // 保存 div 块原来的宽度和鼠标相对于 div 块的水平偏移量
    startWidth = draggable.offsetWidth;
    startX = event.clientX - draggable.offsetLeft;

    var offsetX, offsetY;

    offsetX = event.clientX - draggable.offsetLeft;
    offsetY = event.clientY - draggable.offsetTop;
    let last_newdivid = "";

    document.ondblclick = function (event) {

      if (that.state.isDragging2) {
        that.savepos(divid, bookid, index1, index2, index3);
        if (last_newdivid != "") {
          document.getElementById(last_newdivid).style.backgroundColor = "#fff";
        }
      }
    }

    document.onmousemove = function (event) {
      let result_arr = that.getNewPos(draggable, index1, index2, index3);

      if (result_arr) {
        let newdivid = result_arr[3];
        document.getElementById(newdivid).style.backgroundColor = "#111";
        if (last_newdivid != "" && last_newdivid != newdivid) {
          document.getElementById(last_newdivid).style.backgroundColor = "#fff";
        }
        last_newdivid = newdivid;
      }
      //console.log(offsetX);

      //console.log(event.clientX);
      let movedx = event.clientX - draggable.offsetLeft - offsetX;

      let movedy = event.clientY - draggable.offsetTop - offsetY;

      if (Math.abs(movedx) >= 2 || Math.abs(movedy) >= 2) {
        if (!that.state.isDragging2) {
          that.setState({
            isDragging2: true,

            dragingbookid: bookid,
            // orileft2:draggable.offsetLeft,
            //  oritop2:draggable.offsetTop
          });
        }

        if (draggable.style.cursor == "move") {
          var x = event.clientX - offsetX;
          var y = event.clientY - offsetY;

          draggable.style.left = x + "px";
          draggable.style.top = y + "px";
        } else if (draggable.style.cursor == "n-resize") {
          that.isxiala = true;

          // console.log("aa");
          //   var newHeight = 100;//event.clientY - draggable.offsetTop - startY + startHeight;
          var newHeight =
            event.clientY - draggable.offsetTop - startY + startHeight;
          //  console.log(newHeight);
          // 设置 div 块的高度
          draggable.style.height = newHeight + "px";
          // draggable.style.height = x + "px";
        }
      }
    };

    document.onmouseup = function () {
      
      if (that.isxiala) {
        that.isxiala = false;
        draggable.style.cursor = "grab";
      }
      draggable.style.zIndex = 99;
      document.onmousemove = null;
      document.onmouseup = null;
    };
  }


  getNewPos(moveddivele, index1, index2, index3) {
    let result = "";
    const rect1 = moveddivele.getBoundingClientRect();
    const left1 = rect1.left;
    const top1 = rect1.top;
    let newindex1 = 0;
    let newindex2 = 0;
    let newindex3 = 0;
    let newdivid = "";
    let founded = false;
    outerLoop: for (var j = 0; j < this.state.fixtime.length; j++) {
      let username = this.state.fixtime[j].username;
      let periodno = this.state.fixtime[j].periodno;

      for (var j2 = 0; j2 < this.state.fixtime[j].hours.length; j2++) {
        for (
          var j3 = 0;
          j3 < this.state.fixtime[j].hours[j2].min.length;
          j3++
        ) {
          if (!(j == index1 && j2 == index2 && j3 == index3)) {
            let _divid =
              "Min_" +
              username +
              "_" +
              periodno +
              "_" +
              j +
              "_" +
              j2 +
              "_" +
              j3;

            const moveddiv2 = document.getElementById(_divid);
            //const div2 = document.getElementById("div2");

            const rect2 = moveddiv2.getBoundingClientRect();

            const left2 = rect2.left;
            const top2 = rect2.top;

            const right2 = rect2.right;
            const bottom2 = rect2.bottom;

            const isOverlapping =
              left1 >= left2 &&
              left1 <= right2 &&
              top1 >= top2 &&
              top1 <= bottom2;
            if (isOverlapping) {

              founded = true;
              newdivid = _divid;
              newindex1 = j;
              newindex2 = j2;
              newindex3 = j3;
              break outerLoop;
            }
          }
        }
      }
    }
    if (founded)
      return [newindex1, newindex2, newindex3, newdivid];
    else return false;
  }
  getHistoryBooks(username, history) {
    const { myusername, ukey } = this.props;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&username=" +
      username +
      "&history=" +
      history +
      "&action=bookhistory";

    axios.get(geturl).then((res) => {
      const result = res.data;
  
      if (result.r == 1) {
        this.setState({
          historybooks: result.list,
        });
      } else {
        MyToastShow(this, 2, res.s, 200, 120, "fail", "addemployee");
      }
      // this.setState({});
    });
  }

  getHistoryNote(username, noteortreate) {
    const { myusername, ukey } = this.props;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&username=" +
      username +
      "&noteortreate=" +
      noteortreate +
      "&action=notehistory";
 
    axios.get(geturl).then((res) => {
      const result = res.data;
     
      if (result.r == 1) {
        this.setState({
          historynote: result.list,
        });
      } else {
        MyToastShow(this, 2, res.s, 200, 120, "fail", "addemployee");
      }
      // this.setState({});
    });
  }
  getData(myusername, ukey, id) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=bookinfo&id=" +
      id +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      let bookdata = this.state.bookdata;
      bookdata.employee = result.employee;
      bookdata.nickname = result.nickname;
      bookdata.periodno = result.periodno;
      bookdata.fromtime = result.fromtime;
      bookdata.totime = result.totime;

      //  let groupright = eval(result.groupright);

      this.checkDay(getDateFromPeriodno(result.periodno), 1);

      this.setState({
        editid: result.id,

        username: result.username,
        serviceid: result.serviceid,
        telephone: result.telephone,
        // selectedemployee: result.employee,
        //  employeeusername: result.employee,
        usertel: result.telephone,
        //  fromtime: result.fromtime,
        // totime: result.totime,
        //  periodno: result.periodno,
        serviceminute: result.minutes,
        remark: result.remark,
        status: result.status,
        tickettip: result.tickettip,
        bookdata: bookdata,
        savedbookdata: bookdata,
        oldemployee: result.nickname,
        oldperiodno: result.periodno,
        oldfromtime: result.fromtime,
        oldtotime: result.totime,
      });
      // this.setState({}); /**/
    });
  }
  ShowHistory(username, history) {
    this.ShowUser(username, 1);
    if (history == 1) {
      this.setState({
        defaultform: "historybook",
      });
    } else if (history == 0) {
      this.setState({
        defaultform: "upcomingbook",
      });
    }
    else if (history == 3) {
      this.setState({
        defaultform: "notehistory",
      });
    }
    else if (history == 4) {
      this.setState({
        defaultform: "treatehistory",
      });
    }
    if(history<=1)
    {

    this.getHistoryBooks(username, history);
    }
    else
    {
      this.getHistoryNote(username,history);
    }
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  componentWillUpdate() { }
  componentDidUpdate() { }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    zsxm: state.getIn(["gdata", "zsxm"]),
  };
};

export default connect(mapStateToProps, null)(Index);
