import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT } from "@/common/gdata/global.js";
import { withRouter } from "react-router-dom";
import {
  Fenye,
  MyToast,

  checkUserLogin,
  getDateFromPeriodno,
} from "@/jscript/common.js";
import { Pages } from "@/pages/type/style.js";
//import $ from "jquery";

import "@/css/user.css";
import "@/css/react-datepicker.css";
//import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
    marginLeft:"3px"
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
  radio: {
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    marginRight: "3px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  conditiondiv: {
    width: "100%",
    paddingLeft: "5px",
    fontWeight: "bold",
    height: "30px",
    lineHeight: "30px",
  },
  checkout: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },
  field: {
    display: "inline-block",
    paddingLeft: "10px",
    paddingRight: "10px",

    borderBottom: "1px solid #000",
    marginRight: "18px",
    marginLeft:"5px"
  },
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      mounted: 0,
      title: "",
      list: [],
      schusername: "",

      p_page: 1,
      p_username: "",
      p_zsxm: "",
      p_fromdate: "",
      p_todate: "",
      schfromdate: "",
      fromdate: "",
      schtodate: "",
      todate: "",
      error: "",

      postresult: "",
      //  page: 1,
      pagesize: 30,
      totalpage: 0,
      page: 1,

      mytoast: {
        show: false,
      },
      //   page: 1,
    };
  }

  render() {
    const { myusername, myusertype } = this.props;

     let checkstatus = checkUserLogin(this.props, myusername);

     if (checkstatus != "") return checkstatus;
    // let _username = TurnNull(this.state.username);
    //let _name = TurnNull(this.props.name);
    // let _telephone = TurnNull(this.props.telephone);
    // const Now = new Date();

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>

        <div className="row textright">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10  textleft schform">
            <input
              style={{
                float: "left",
                width: "150px",
              }}
              className="schfield"
              name="schusername"
              value={this.state.schusername}
              onChange={(ref) => this.changeField(ref, "schusername")}
              placeholder="Account"
            ></input>

            <div
              style={{
                width: "130px",
                float: "left",
              }}
            >
              <DatePicker
                className="schfield schdate"
                selected={this.state.fromdate}
                onChange={(ref) => this.setFromdate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input from date"
              />
            </div>
            <div
              style={{
                width: "22px",
                float: "left",
              }}
            >
              -
            </div>
            <div
              style={{
                width: "130px",
                float: "left",
              }}
            >
              <DatePicker
                className="schfield schdate"
                selected={this.state.todate}
                onChange={(ref) => this.setTodate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input end date"
              />
            </div>
            <input
              onClick={() => this.search()}
              type="button"
              name="schbut"
              value=" Search "
              className="add"
            ></input>

            {this.state.list.length>0&& (
              <input
                style={{
                  marginLeft: "25px",
                  height: "30px",
                  borderRadius: "5px",
                  width: "100px",
                }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/customer/notelist?username=" +
                      this.state.schusername +
                      "&fromdate=" +
                      this.state.schfromdate +
                       "&todate=" +
                      this.state.schtodate +
                      ""
                  )
                }
              ></input>
            )}
          </div>
        </div>
        <div style={{ padding: "15px" }}></div>
        <div className="PageNext" id="content">
          {this.state.list.map((item, index) => {
            // if (_status == "2") _status = "Commented ";
            return (
             <div
                className="row "
                key={"group" + index}
                style={{ position: "relative", paddingBottom: "15px",borderBottom:"2px solid #aaaaaa",margin:"15px" }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="row" style={{ paddingTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Treatment notes for:
                  <span style={LCss.field}>{item.customer}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Date:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {getDateFromPeriodno(item.periodno)}
                  </span>
                  Time:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {item.fromtime} - {item.totime}
                  </span>
                  Duration:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {item.minutes} min
                  </span>
                  Fee $:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "100px",
                      marginRight: "0px",
                    })}
                  >
                    {item.price}
                  </span>
                </div>
              </div>

               <div className="row">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
                  style={{ height: "auto",lineHeight: "55px",display:"flex",
                  alignContent:"center",alignItems:"center",
           }}
                >
                  <div
                    style={{
                      width: "50%",
                      
                      borderBottom: "0px",
                      float: "left",
                      display:"flex",
                 
                 
                    }}
                  >
                   
                    Informed consent received: {" "}
                    <input
                      type="checkbox"
                      name="treatment"
                      value="Y"
                      onChange={(ref) =>
                        this.changeField(ref,  "")
                      }
                      checked={item.treatment == "Y" ? true : false}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "3px",marginLeft: "3px",
                      }}
                    ></input>
                    {"treatment"}
                    <input
                      type="checkbox"
                      name="assessment"
                      value="Y"
                      onChange={(ref) =>
                        this.changeField(ref,  "")
                      }
                      checked={item.assessment == "Y" ? true : false}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginLeft: "8px",
                        marginRight: "3px",
                      }}
                    ></input>
                    {"assessment"}
                  </div>
                  <div style={{ float: "left", width: "45%",height: "55px",lineHeight: "55px", 
                display:"flex",justifyContent:"center",
                  alignContent:"center",alignItems:"center",}}>
                    Therapist:
                    <span style={LCss.signcave}>
                       
                      
                          {item.therapistsign!=""&&<img src={item.therapistsign} style={{}}></img>}
                      
                      
                    </span>
                    
                  </div>
                 
                </div>
              </div>

              <div className="row" style={{ height: "25px" }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  <span style={{ fontWeight: "bold" }}>Techniques Used:</span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  {item.techniquesused.map((item, index) => {
                    let q = "";
                    let haveq = false;
                    if (typeof item.q != "undefined") {
                      haveq = true;
                      q = item.q;
                    }
                    let width = "160px";
                    if (haveq) width = "480px";
                    return (
                      <div
                        key={"ser" + index}
                        style={{
                          display: "flex",
                          width: width,
                          float: "left",
                          padding: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          name="ser"
                          value="Y"
                          onChange={(ref) =>
                        this.changeField(ref,  "")
                      }
                          checked={item.c == "Y" ? true : false}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "3px",
                          }}
                        ></input>
                        {item.name}

                        {haveq && (
                          <div
                            style={{
                              paddingLeft: "5px",
                            }}
                          >
                            {q}{" "}
                            <input
                              className="printField"
                              style={{ height: "25px", with: "100px" }}
                              value={item.a}
                              onChange={(ref) =>
                        this.changeField(ref,  "")
                      }
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row" style={{ height: "25px" }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  <span style={{ fontWeight: "bold" }}>Areas Treated:</span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  {item.acerstreated.map((item, index) => {
                    let q = "";
                    let haveq = false;
                    if (typeof item.q != "undefined") {
                      haveq = true;
                      q = item.q;
                    }
                    let width = "160px";
                    if (haveq) width = "320px";
                    return (
                      <div
                        key={"acer" + index}
                        style={{
                          display: "flex",
                          width: width,
                          float: "left",
                          padding: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          name="ser"
                          value="Y"
                          onChange={(ref) =>
                        this.changeField(ref,  "")
                      }
                          checked={item.c == "Y" ? true : false}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "3px",
                          }}
                        ></input>
                        {item.name}

                        {haveq && (
                          <div
                            style={{
                              paddingLeft: "5px",
                            }}
                          >
                            , {q}{" "}
                            <input
                              className="printField"
                              style={{ height: "25px", with: "100px" }}
                              value={item.a}
                              onChange={(ref) =>
                        this.changeField(ref,  "")
                      }
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Clinical findings:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={item.clinicalfinding}
                      onChange={(ref) =>
                        this.changeField(ref, "clinicalfinding")
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Clients reaction / feedback:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={item.clientsreaction}
                      onChange={(ref) =>
                        this.changeField(ref, "clientsreaction")
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Recommended Self-Care:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={item.recommandedselfcare}
                      onChange={(ref) =>
                        this.changeField(ref, "recommandedselfcare")
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <Pages>
            {Fenye(
              "/user/customer/notelist?username=" +
                this.state.schusername +
                "&fromdate=" +
                this.state.schfromdate +
                "&todate=" +
                this.state.schtodate +
                "&pagesize=" +
                this.state.pagesize +
                "",
              this.state.page,
              this.state.totalpage
            )}
          </Pages>
        </div>

        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }

  printPage(url) {
 
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    //  document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
    window.open(url, "_self");
  }
  //changeField(e, field) {}
  // setCheck(ref, field) {}
  search() {
    const { myusername, ukey } = this.props;

    let fromdate = "";
    if (this.state.fromdate != "") {
      fromdate = this.state.fromdate;
      fromdate =
        fromdate.getFullYear() +
        "-" +
        (fromdate.getMonth() + 1) +
        "-" +
        fromdate.getDate();
    }

    let todate = "";
    if (this.state.todate != "") {
      todate = this.state.todate;
      todate =
        todate.getFullYear() +
        "-" +
        (todate.getMonth() + 1) +
        "-" +
        todate.getDate();
    }

    let schusername = this.state.schusername;
    //let order = this.state.order;
    // let page = this.state.page;

    let url =
      "/user/customer/notelist?username=" +
      schusername +
      "&fromdate=" +
      fromdate +
      "&todate=" +
      todate +
      "&pagesize=" +
      this.state.pagesize +
      "&page=1";

    this.props.history.push(url);
  }
  setFromdate(val) {
    let fromdate = "";
    let schfromdate = "";
    if (val == null) {
      schfromdate = "";
      fromdate = "";
    } else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      fromdate = date;
      schfromdate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
    }

    this.setState({
      fromdate: fromdate,
      schfromdate: schfromdate,
    });
  }

  setTodate(val) {
    let todate = "";
    let schtodate = "";
    if (val == null) {
      schtodate = "";
      todate = "";
    } else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      todate = date;
      schtodate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
    }

    this.setState({
      todate: todate,
      schtodate: schtodate,
    });
  }
setCheck(e,field){}
  changeField(e, field) {
    let val = e.target.value;

    if (field == "schusername") {
      this.setState({
        schusername: val,
      });
    } else if (field == "schzsxm") {
      this.setState({
        schzsxm: val,
      });
    } else if (field == "schtelephone") {
      this.setState({
        schtelephone: val,
      });
    } else if (field == "schservice") {
      this.setState({
        schservice: val,
      });
    }
  }
  getData(
    myusername,
    ukey,
    page,
    username,

    fromdate,
    todate
  ) {
    /*   let name = this.state.schzsxm;
    let birthday = "";
    if (this.state.birthday != "") {
      birthday = this.state.birthday;
      birthday =
        birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate();
    } */
    // let username = this.state.schusername;
    //let telephone = this.state.schtelephone;
    //let order = this.state.order;
    //let page = this.state.page;

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&customer=" +
      username +
      "&todate=" +
      todate +
      "&fromdate=" +
      fromdate +
      "&page=" +
      page +
      "&pagesize=" +
      this.state.pagesize +
      "&action=notelist";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;

      /*  if (mounted == 1) {
        this.setState({
          mounted: 1,
        });
      } */
      this.setState({
        list: result.list,
        // totalpage: result.num,
        totalpage: Math.ceil(result["num"] / this.state.pagesize),
        //     num: result["num"],
        //  page: page,
      });
      
    });
  }
  loadData(mounted) {
    const { myusername, ukey } = this.props;
    var {
      page,
      username,

      fromdate,
      todate,
    } = queryString.parse(this.props.location.search);
    if (page == null) page = 1;
    if (username == null) username = "";

    if (fromdate == null) fromdate = "";
    if (todate == null) todate = "";
    var dfromdate = "";
    if (fromdate != "") {
      dfromdate = new Date(fromdate);
    }

    var dtodate = "";
    if (todate != "") {
      dtodate = new Date(todate);
    }


    if (mounted==0) {
    this.setState({
       
        page: page,
        schusername: username,
        schfromdate: fromdate,
        schtodate: todate,
        fromdate: dfromdate,
        todate: dtodate,

        p_username: username,
        p_page: page,
        p_todate: todate,
        p_fromdate: fromdate,
      });
      this.getData(
        myusername,
        encodeURIComponent(ukey),
        page,
        username,

        fromdate,
        todate
      );
      
    } else {
      if (
        page !== this.state.p_page ||
        username !== this.state.p_username ||
        fromdate !== this.state.p_fromdate ||
        todate !== this.state.p_todate
      ) {
        this.getData(
          myusername,
          encodeURIComponent(ukey),
          page,
          username,

          fromdate,
          todate
        );

        this.setState({
          p_username: username,
          p_page: page,
          p_todate: todate,
          p_fromdate: fromdate,
        });
      }
    }
  }
  componentDidMount() {

      var title = "Note List";
    document.title = title;
    this.loadData(0);

    this.setState({
     title: title,
      mounted: 1,
    }); /* */
    // this.getEmployeeData(myusername, encodeURIComponent(ukey));
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    if (this.state.mounted == 1) {
      this.loadData(1);
     
    }
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),

 
  };
};

export default connect(mapStateToProps, null)(withRouter(Index));
